/**
 * ヘッダーのナビゲーション
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import NextLogin from './NextLogin'
import Dialog from 'material-ui/Dialog'
import DialogNotifi from './Dialog'
import HeaderNotifi from './HeaderNotifi'
import * as Fetch from '../util/Fetch'
import { ANSWER_TELLER, fortuneTellerStatus, MESSAGE_TYPE_SOCKET, TYPE_LOCALSTORAGE } from '../constants/Config'
import * as RequestOfflineActions from '../actions/RequestOfflineActions'
import * as ChatRequestActions from '../actions/ChatRequestActions'
import * as MyProfileActions from '../actions/MyProfileActions'
import * as RequestHistoryActions from '../actions/RequestHistoryActions'
import * as SuspendRequestChatActions from '../actions/SuspendRequestChatActions'
import * as IdleActions from '../actions/IdleActions'
import { playNotificationSound, pauseNotificationSound, pauseSound } from '../util/Sound'
import FontIcon from 'material-ui/FontIcon'
import { BottomNavigationItem } from 'material-ui/BottomNavigation'
import Row from 'react-bootstrap/lib/Row'
import { Nav, Col, Navbar } from 'react-bootstrap'
import { glasConfig } from '../constants/Config'
import request from 'axios'
import * as AnswerManagementActions from './../actions/AnswerManagementActions'
import DialogWelcomeAnswer from './DialogWelComeAnswer'
import moment from 'moment'
import * as ActionTypes from '../constants/ActionTypes'
import { CHAT_NUMBER_TOTAL } from '../constants/ActionTypes'
import { CHAT_REQUEST_UNREAD_REFRESH } from '../constants/ActionTypes'
import { addItemToList } from '../actions/ChatRequestActions'
import * as ContactActions from '../actions/ContactActions'
import { removeItemFromList } from '../actions/ChatRequestActions'
import { CHAT_REQUEST_READ_REFRESH } from '../constants/ActionTypes'
import { REQUEST_HISTORY_UNREAD_REFRESH } from '../constants/ActionTypes'
import { REQUEST_HISTORY_READ_REFRESH } from '../constants/ActionTypes'
import { msgTypeMap } from '../constants/ConfigMessage'
import { getUserId } from '../assets/help/help'
import { callApiGetSortRequestOffline } from './LeftOffline'
import { REQUEST_OFFLINE } from '../constants/ActionTypes'
import User from '../models/User'
import BellRef from '../components/ChatContent/Frames/BellRef'
import { isIOS, isSafari } from 'react-device-detect'
import { ModalMissingAnswer } from './Modals/ModalAnswer/ModalMissingAnswer'
import { isPWA } from '../helper/helpFunction'
import { DialogAppealMessage } from './LeftMenu/DialogAppealMessge'
import { Chat } from '@material-ui/icons'
import * as MyNotificationActions from '../actions/MyNotificationActions'
import * as RankingEventActions from '../actions/RankingEventActions'
import { FireMsg } from '../util/Firebase'
import { STATUS_EVENT_RANKING } from './Event/DialogEventRanking'
const debounce = require('lodash.debounce')

class Header extends Component {
	constructor(props) {
		super(props)
		/**
		 * showNextLogin: 次回ログイン日時の表示判定
		 * showNotifiLog：通知ログの表示判定
		 */
		this.state = {
			userId: this.props.userId,
			showNextLogin: false,
			showNotifiLog: false,
			showDialogWelcome: false,
			focused: true,
			showModalMissingAnswer: false,
			showModalVerifyAnswer: false,
			onClickAnswer: true,
		}
	}

	componentWillMount() {
		const { dispatch } = this.props
		const token = localStorage.getItem('token')
		this.sound = new Audio('/pa.mp3')
		request
			.get(
				glasConfig.url_base +
				glasConfig.path_jpns_notifications +
				'/unreadbestanswer',
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((response) => {
				if (response.data.code == 0) {
					localStorage.setItem(
						'numberUnreadBestAnswer',
						response.data.data
					)
					localStorage.setItem('showPopUpBestAnswer', 'false')
					if (response.data.data > 0) {
						localStorage.setItem('showPopUpBestAnswer', 'true')
						dispatch(
							AnswerManagementActions.add(response.data.data)
						)
					}
				}
			})
	}

	componentDidMount() {
		// let path = window.location.pathname;

		const socket = User.getSocket()
		if (!socket.hasListeners('userRequest')) {
			// console.log('init socket')
			this.receiveNotification()
		}

		const { MyProfile } = this.props

		if ((!MyProfile.data.isAllowChangeStatus || !Fetch.isAgeVerify(MyProfile.data.verifyAge)) && !isPWA()) {
			this.setState({
				onClickAnswer: false
			})
		}

		if (!localStorage.hasOwnProperty(TYPE_LOCALSTORAGE.PUSH_NOTIFICATION_TOKEN) && FireMsg && !isSafari && !isIOS) {
			this.requestPremissionNotification()
		}

		window.addEventListener('blur', this.onBlur)
		window.addEventListener('focus', this.onFocus)
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			userId: nextProps.userId,
		})
	}

	componentWilUnmount() {
		window.removeEventListener('blur', this.onUnBlur)
		window.removeEventListener('focus', this.onUnBlur)
	}

	onBlur = () => {
		this.setState({ focused: false })
	}

	onFocus = () => {
		this.setState({ focused: true })
	}

	makeObjectKey = (message = {}) => {
		let itemNew = {}
		Object.assign(itemNew, { [getUserId(message)]: message })
		return itemNew
	}

	fetchProfileDebounce = debounce(() => {
		const { dispatch } = this.props
		dispatch(MyProfileActions.fetch())
	}, 1000)

	resetToggleMissing = () => {
		this.setState({
			showModalMissingAnswer: false,
		})
	}

	resetToggleVerify = () => {
		this.setState({
			showModalVerifyAnswer: false,
		})
	}

	checkModalMissingAnswer = () => {
		const { MyProfile, dispatch } = this.props
		if (!isPWA()) {
			dispatch(MyProfileActions.fetch())
			if ((MyProfile.data.isAllowChangeStatus && Fetch.isAgeVerify(MyProfile.data.verifyAge))) {
				this.setState({
					onClickAnswer: true
				})
				this.props.history.push("/home/answer")
			} else {
				this.setState({
					onClickAnswer: false
				})
			}

			if (!MyProfile.data.isAllowChangeStatus) {
				this.setState({
					showModalMissingAnswer: true
				})
			}

			if (!Fetch.isAgeVerify(MyProfile.data.verifyAge) && MyProfile.data.isAllowChangeStatus) {
				DialogNotifi.getDialog().handleOpen('AGEAUTH')
			}
		}
	}

	requestPremissionNotification = () => {
		const { dispatch } = this.props
		window.indexedDB
			.databases()
			.then((r) => {
				for (var i = 0; i < r.length; i++)
					window.indexedDB.deleteDatabase(r[i].name)
			}).then(() => {
				FireMsg.requestPermission()
					.then(() => {
						//ユーザー毎のトークンを取得
						FireMsg.getToken()
							.then((token) => {
								const req = {
									deviceId: 'stella-web',
									pushNotificationToken: token,
									applicationVersion: 'stella-web'
								}
								dispatch(MyNotificationActions.updatePushNotificationToken(req))
							})
							.catch((err) => {
								console.log('1 Unable to get permission to notify.', err)
							})
					})
					.catch((err) => {
						console.log('2 Unable to get permission to notify.', err)
					})
			})
	}

	/**
	 * 通知を受信する
	 */
	receiveNotification() {
		const { dispatch } = this.props
		const socket = User.getSocket()
		const tellerId = Fetch.tellerId()
		//占い師のポイントが送られて来る
		socket.on('point', (res) => {
			const message = JSON.parse(res)

			if (message.msgType === 'POINT') {
				if (tellerId === message.toId && message.fromId === 'SERVER') {
					const newPoint = Number(message.curPoint)
					dispatch(
						MyProfileActions.merge({
							point: newPoint,
						})
					)
				}
			}
		})

		socket.on('userRequest', (res) => {
			const message = JSON.parse(res)
			const {
				ChatRequestRead,
				ChatRequestUnRead,
				ChatRequest,
				RequestHistoryUnRead,
				RequestHistoryRead,
				ChatNumberTotal,
				MyProfile
			} = this.props
			let ChatRequestUnReadOld =
				ChatRequestUnRead.data[getUserId(message)]
			let ChatRequestReadOld = ChatRequestRead.data[getUserId(message)]
			let chattingUnreadNumber = ChatNumberTotal.data.chattingUnreadNumber
			let chatedUnreadNumber = ChatNumberTotal.data.chatedUnreadNumber
			let RequestHistoryUnReadOld =
				RequestHistoryUnRead.data[getUserId(message)]
			let RequestHistoryReadOld =
				RequestHistoryRead.data[getUserId(message)]
			let totalChatRequestUnRead = 0
			let totalChatRequestRead = 0
			let totalRequestHistoryUnRead = 0
			let totalRequestHistoryRead = 0
			if (
				ChatRequestUnReadOld === undefined &&
				ChatRequestReadOld === undefined &&
				message.msgType !== 'ACCEPT_CHAT'
			) {
				dispatch(
					ChatRequestActions.requestChatUnRead({
						page: 0,
						size: 24,
						inConversation: true,
					})
				)
				dispatch(
					ChatRequestActions.requestChatRead({
						page: 0,
						size: 24,
						inConversation: true,
					})
				)
			}

			if (
				message.msgType === 'CANCEL_REQUEST_CHAT' ||
				message.msgType === 'REQUEST_LIMIT_TIME'
			) {
				//リクエストキャンセル
				const deluserId =
					tellerId === message.toId ? message.fromId : message.toId
				dispatch(ChatRequestActions.del(deluserId))

				dispatch(
					removeItemFromList(
						ChatRequestUnRead.data,
						getUserId(message),
						CHAT_REQUEST_UNREAD_REFRESH
					)
				)
				dispatch(
					removeItemFromList(
						ChatRequestRead.data,
						getUserId(message),
						CHAT_REQUEST_READ_REFRESH
					)
				)
				if (message.isPin) {
					const dataPin = ChatRequestUnReadOld !== undefined ? ChatRequestUnReadOld : ChatRequestReadOld
					dispatch(RequestHistoryActions.addItemToPin({ message: { ...dataPin, isPin: true, msgType: message.msgType, unreadMsgBadge: message.numberUnreadMsg } }))
				}

				if (ChatRequestReadOld !== undefined) {
					ChatRequestReadOld.msgType = 'CANCEL_REQUEST_CHAT'
					dispatch(
						addItemToList(
							RequestHistoryRead.data,
							ChatRequestReadOld,
							getUserId(message),
							REQUEST_HISTORY_READ_REFRESH
						)
					)
				}

				if (ChatRequestUnReadOld !== undefined) {
					ChatRequestUnReadOld.msgType = 'CANCEL_REQUEST_CHAT'
					totalChatRequestUnRead = totalChatRequestUnRead
					if (message.numberUnreadMsg > 0) {
						dispatch(
							addItemToList(
								RequestHistoryUnRead.data, { ...ChatRequestUnReadOld, unreadMsgBadge: message.numberUnreadMsg },
								getUserId(message), REQUEST_HISTORY_UNREAD_REFRESH)
						);
					} else {
						dispatch(
							addItemToList(
								RequestHistoryRead.data,
								ChatRequestUnReadOld,
								getUserId(message),
								REQUEST_HISTORY_READ_REFRESH
							)
						)
					}
				}

				Object.values(this.props.ChatRequestUnRead.data)
					.reverse()
					.map((data) => {
						// setTimeout(() => DialogNotifi.getDialog().handleOpen('CHAT',data), 300);
					})

				//ダイアログ非表示
				if (Object.keys(this.refs).length) {
					DialogNotifi.getDialog().handleClose()
				}

				dispatch({
					type: CHAT_NUMBER_TOTAL,
					data: {
						chattingUnreadNumber:
							chattingUnreadNumber - totalChatRequestUnRead,
						chatedUnreadNumber:
							chatedUnreadNumber + totalChatRequestUnRead,
					},
				})

				dispatch(SuspendRequestChatActions.doHideBell())
				dispatch(
					SuspendRequestChatActions.doRemoveRequest(message.msgId)
				)
				BellRef.getModal().forceUpdateBell()
			} else if (message.msgType === 'ACCEPT_CHAT') {
				// if (isIOS) {

				if (MyProfile.data.isDisableSoundRequestChat && MyProfile.data.fortuneTellerStatus === fortuneTellerStatus.chatting) {
				} else {
					playNotificationSound(this.props.MyProfile.data.ringtoneNumber)
				}
				pauseSound()
				// }
				// 通知種別：チャット相談申し込み
				// 処理１：オフラインリクエストから削除
				// 処理２：対応中一覧に追加
				const listRequest = this.props.ChatRequest.data
				if (!listRequest[this.state.userId]) {
					// console.log('openChathhhhh');
					const { IdleManagement } = this.props
					if (
						IdleManagement.action ===
						ActionTypes.USER_IDLE_ACTION_SHOW_POPUP
					) {
						// Hide noaction popup
						dispatch(IdleActions.syncContinueWaiting())

						// Show request chat popup
						setTimeout(
							() =>
								DialogNotifi.getDialog().handleOpen(
									'CHAT',
									message
								),
							300
						)
					} else {
						DialogNotifi.getDialog().handleOpen('CHAT', message)
					}
				}

				// dispatch(removeItemFromList(RequestHistoryRead.data, getUserId(message), REQUEST_HISTORY_READ_REFRESH));
				// dispatch(removeItemFromList(RequestHistoryUnRead.data, getUserId(message), REQUEST_HISTORY_UNREAD_REFRESH));
				let params = {
					fromId: message.fromId,
					msgId: message.msgId,
					msgType: message.msgType,
					official: message.official,
					serverTime: message.serverTime,
					toId: message.toId,
					accept: false,
					unreadMsgBadge: 0,
					userName: message.userName,
					...message
				}

				if (RequestHistoryUnReadOld !== undefined) {
					// params.unreadMsgBadge = params.unreadMsgBadge + RequestHistoryUnReadOld.unreadMsgBadge;
					// totalRequestHistoryUnReadUnRead = RequestHistoryUnReadOld.unreadMsgBadge;
				}
				dispatch(
					addItemToList(
						ChatRequestUnRead.data,
						params,
						getUserId(params),
						CHAT_REQUEST_UNREAD_REFRESH
					)
				)
				// totalChatRequestUnRead = totalChatRequestUnRead + params.unreadMsgBadge;
				/*add total read*/
				dispatch({
					type: CHAT_NUMBER_TOTAL,
					data: {
						chattingUnreadNumber:
							chattingUnreadNumber + totalChatRequestUnRead,
						chatedUnreadNumber:
							chatedUnreadNumber - totalRequestHistoryUnRead,
					},
				})

				dispatch(RequestOfflineActions.del(getUserId(params)))
			} else if (message.msgType === 'CLOSE_CHAT') {
				this.fetchProfileDebounce()
				dispatch(
					removeItemFromList(
						ChatRequestRead.data,
						getUserId(message),
						CHAT_REQUEST_READ_REFRESH
					)
				)
				dispatch(
					removeItemFromList(
						ChatRequestUnRead.data,
						getUserId(message),
						CHAT_REQUEST_UNREAD_REFRESH
					)
				)
				if (message.isPin) {
					let dataPin
					if (ChatRequestUnReadOld !== undefined) {
						ChatRequestUnReadOld.unreadMsgBadge = ChatRequestUnReadOld.unreadMsgBadge + 1
						totalChatRequestUnRead = totalChatRequestUnRead + ChatRequestUnReadOld.unreadMsgBadge
						dataPin = ChatRequestUnReadOld

					} else if (ChatRequestReadOld !== undefined) {
						dataPin = ChatRequestReadOld
						if (message.fromId !== tellerId) {
							let userId = getUserId(message)
							let urlParam = window.location.href
							if (urlParam.indexOf(userId) === -1) {
								ChatRequestReadOld.unreadMsgBadge = 1
								totalChatRequestRead = 1
							} else {
								ChatRequestReadOld.unreadMsgBadge = 0
								totalChatRequestRead = 0
							}
						}
					}
					dispatch(RequestHistoryActions.addItemToPin({
						message: {
							...dataPin,
							isPin: true,
							value: msgTypeMap[message.msgType].msg
						}
					}))
					dispatch(
						addItemToList(
							RequestHistoryUnRead.data,
							dataPin,
							getUserId(message),
							REQUEST_HISTORY_UNREAD_REFRESH
						)
					)
				}

				if (ChatRequestUnReadOld !== undefined && !message.isPin) {
					if (message.msgType === 'CLOSE_CHAT') {
						ChatRequestUnReadOld.value =
							msgTypeMap[message.msgType].msg
						ChatRequestUnReadOld.serverTime = message.serverTime
					}
					ChatRequestUnReadOld.unreadMsgBadge =
						ChatRequestUnReadOld.unreadMsgBadge + 1
					// ChatRequestUnReadOld.unreadMsgBadge // fix bug #113434 old +1
					totalChatRequestUnRead =
						totalChatRequestUnRead +
						ChatRequestUnReadOld.unreadMsgBadge
					dispatch(
						addItemToList(
							RequestHistoryUnRead.data,
							ChatRequestUnReadOld,
							getUserId(message),
							REQUEST_HISTORY_UNREAD_REFRESH
						)
					)
				}

				if (ChatRequestReadOld !== undefined && !message.isPin) {
					if (message.msgType === 'CLOSE_CHAT') {
						ChatRequestReadOld.value =
							msgTypeMap[message.msgType].msg
						ChatRequestReadOld.serverTime = message.serverTime
					}
					if (message.fromId !== tellerId) {
						let userId = getUserId(message)
						let urlParam = window.location.href
						if (urlParam.indexOf(userId) === -1) {
							ChatRequestReadOld.unreadMsgBadge = 1
							totalChatRequestRead = 1
						} else {
							ChatRequestReadOld.unreadMsgBadge = 0
							totalChatRequestRead = 0
						}
					}
					dispatch(
						addItemToList(
							RequestHistoryUnRead.data,
							ChatRequestReadOld,
							getUserId(message),
							REQUEST_HISTORY_UNREAD_REFRESH
						)
					)
				}
				dispatch({
					type: CHAT_NUMBER_TOTAL,
					data: {
						chattingUnreadNumber:
							chattingUnreadNumber -
							totalChatRequestRead -
							totalChatRequestUnRead + 1, // fix bug #113434 old not +1
						chatedUnreadNumber:
							chatedUnreadNumber +
							totalChatRequestRead +
							totalChatRequestUnRead
					},
				})
				callApiGetSortRequestOffline(this.props)
			} else if (
				message.msgType === 'NO_ACTION_LONG_TIME' ||
				message.msgType === 'NO_CONNECT_AVAILABLE'
			) {
				dispatch(
					removeItemFromList(
						ChatRequestRead.data,
						getUserId(message),
						CHAT_REQUEST_READ_REFRESH
					)
				)
				dispatch(
					removeItemFromList(
						ChatRequestUnRead.data,
						getUserId(message),
						CHAT_REQUEST_UNREAD_REFRESH
					)
				)
				if (ChatRequestUnReadOld !== undefined) {
					ChatRequestUnReadOld.value = msgTypeMap[message.msgType].msg
					ChatRequestUnReadOld.serverTime = message.serverTime
					totalChatRequestUnRead =
						totalChatRequestUnRead +
						ChatRequestUnReadOld.unreadMsgBadge
					dispatch(
						addItemToList(
							RequestHistoryUnRead.data,
							ChatRequestUnReadOld,
							getUserId(message),
							REQUEST_HISTORY_UNREAD_REFRESH
						)
					)
				}

				if (ChatRequestReadOld !== undefined) {
					ChatRequestReadOld.value = msgTypeMap[message.msgType].msg
					ChatRequestReadOld.serverTime = message.serverTime
					dispatch(
						addItemToList(
							RequestHistoryRead.data,
							ChatRequestReadOld,
							getUserId(message),
							REQUEST_HISTORY_READ_REFRESH
						)
					)
				}
				dispatch({
					type: CHAT_NUMBER_TOTAL,
					data: {
						chattingUnreadNumber:
							chattingUnreadNumber - totalChatRequestUnRead,
						chatedUnreadNumber:
							chatedUnreadNumber + totalChatRequestUnRead,
					},
				})
			} else if (message.msgType === 'USER_REQUEST_CALL') {
				playNotificationSound()
				dispatch(
					RequestOfflineActions.merge({
						userId: message.fromId,
						data: {
							msgId: message.msgId,
							fromId: message.fromId,
							toId: message.toId,
							fromName: message.userName,
							type: 2,
							msgType: message.msgType,
							serverTime: message.serverTime,
						},
					})
				)
			} else if (message.msgType === 'USER_REQUEST_CHAT') {
				if (MyProfile.data.isDisableSoundRequestChat && MyProfile.data.fortuneTellerStatus === fortuneTellerStatus.chatting) {
				} else {
					playNotificationSound()
				}
				dispatch(
					RequestOfflineActions.merge({
						userId: message.fromId,
						data: {
							msgId: message.msgId,
							fromId: message.fromId,
							toId: message.toId,
							fromName: message.userName,
							type: 1,
							msgType: message.msgType,
							serverTime: message.serverTime,
						},
					})
				)
			} else if (message.msgType === 'CLOSE_CALL') {
				dispatch(
					removeItemFromList(
						ChatRequestRead.data,
						getUserId(message),
						CHAT_REQUEST_READ_REFRESH
					)
				)

				dispatch(
					removeItemFromList(
						ChatRequestUnRead.data,
						getUserId(message),
						CHAT_REQUEST_UNREAD_REFRESH
					)
				)
				callApiGetSortRequestOffline(this.props)
				dispatch(ChatRequestActions.del(message.fromId))
				this.chgCallStatus('CLOSE')
				if (Object.keys(this.refs).length) {
					DialogNotifi.getDialog().handleOpen('CALL', {
						endType: 'CLOSE',
					})
				}
			} else if (message.msgType === 'RESPONSE_REQUEST_CHAT') {
				if (message.value === 'OK') {
					this.fetchProfileDebounce()
					if (RequestHistoryUnReadOld !== undefined) {
						totalRequestHistoryUnRead =
							totalRequestHistoryUnRead +
							RequestHistoryUnReadOld.unreadMsgBadge
					}

					dispatch(
						removeItemFromList(
							ChatRequestRead.data,
							getUserId(message),
							CHAT_REQUEST_READ_REFRESH
						)
					)
					dispatch(
						removeItemFromList(
							ChatRequestUnRead.data,
							getUserId(message),
							CHAT_REQUEST_UNREAD_REFRESH
						)
					)
					dispatch(
						removeItemFromList(
							RequestHistoryRead.data,
							getUserId(message),
							REQUEST_HISTORY_READ_REFRESH
						)
					)
					dispatch(
						removeItemFromList(
							RequestHistoryUnRead.data,
							getUserId(message),
							REQUEST_HISTORY_UNREAD_REFRESH
						)
					)

					if (ChatRequestUnReadOld !== undefined) {
						ChatRequestUnReadOld.msgType = message.msgType
						totalChatRequestUnRead =
							totalChatRequestUnRead +
							ChatRequestUnReadOld.unreadMsgBadge
						ChatRequestUnReadOld.unreadMsgBadge = 0
					}

					if (ChatRequestReadOld !== undefined) {
						ChatRequestReadOld.msgType = message.msgType
						totalChatRequestRead =
							totalChatRequestRead +
							ChatRequestReadOld.unreadMsgBadge
						ChatRequestReadOld.unreadMsgBadge = 0
					}

					DialogNotifi.getDialog().handleClose()
				} else if (message.value === 'NOT_OK') {
					const { ChatRequestUnRead } = this.props
					dispatch(
						removeItemFromList(
							ChatRequestUnRead.data,
							message.fromId,
							CHAT_REQUEST_UNREAD_REFRESH
						)
					)
					DialogNotifi.getDialog().handleClose()
				}
				dispatch({
					type: CHAT_NUMBER_TOTAL,
					data: {
						chattingUnreadNumber:
							chattingUnreadNumber -
							totalChatRequestUnRead -
							totalChatRequestRead,
						chatedUnreadNumber:
							chatedUnreadNumber - totalRequestHistoryUnRead,
					},
				})
			} else if (message.msgType === 'RECONNECT_CHAT') {
				if (RequestHistoryReadOld !== undefined) {
					dispatch(
						removeItemFromList(
							RequestHistoryRead.data,
							getUserId(message),
							REQUEST_HISTORY_READ_REFRESH
						)
					)
					dispatch(
						addItemToList(
							ChatRequestRead.data,
							RequestHistoryReadOld,
							getUserId(message),
							CHAT_REQUEST_READ_REFRESH
						)
					)
				}

				if (RequestHistoryUnReadOld !== undefined) {
					dispatch(
						removeItemFromList(
							RequestHistoryUnRead.data,
							getUserId(message),
							REQUEST_HISTORY_UNREAD_REFRESH
						)
					)
					totalRequestHistoryUnRead =
						totalRequestHistoryUnRead +
						RequestHistoryUnReadOld.unreadMsgBadge
					dispatch(
						addItemToList(
							ChatRequestUnRead.data,
							RequestHistoryUnReadOld,
							getUserId(message),
							CHAT_REQUEST_UNREAD_REFRESH
						)
					)
				}
				dispatch({
					type: CHAT_NUMBER_TOTAL,
					data: {
						chattingUnreadNumber: chattingUnreadNumber,
						chatedUnreadNumber:
							chatedUnreadNumber + totalRequestHistoryUnRead,
					},
				})
				this.props.history.push(`/home/chat/${message.fromId}`)
			} else if (message.msgType === 'EXPECTED_REQUEST_CHAT') {
				if (MyProfile.data.isDisableSoundRequestChat && MyProfile.data.fortuneTellerStatus === fortuneTellerStatus.chatting) {
				} else {
					playNotificationSound()
				}
				if (RequestHistoryReadOld !== undefined) {
					dispatch(
						removeItemFromList(
							RequestHistoryRead.data,
							getUserId(message),
							REQUEST_HISTORY_READ_REFRESH
						)
					)
					RequestHistoryReadOld.unreadMsgBadge =
						RequestHistoryReadOld.unreadMsgBadge + 1
					totalRequestHistoryUnRead = totalRequestHistoryUnRead + 1
					RequestHistoryReadOld.msgType = message.msgType
					dispatch(
						addItemToList(
							RequestHistoryUnRead.data,
							RequestHistoryReadOld,
							getUserId(message),
							REQUEST_HISTORY_UNREAD_REFRESH
						)
					)
				}
				if (RequestHistoryUnReadOld !== undefined) {
					dispatch(
						removeItemFromList(
							RequestHistoryUnRead.data,
							getUserId(message),
							REQUEST_HISTORY_UNREAD_REFRESH
						)
					)
					RequestHistoryUnReadOld.unreadMsgBadge =
						RequestHistoryUnReadOld.unreadMsgBadge + 1
					totalRequestHistoryUnRead = totalRequestHistoryUnRead + 1
					RequestHistoryUnReadOld.msgType = message.msgType
					dispatch(
						addItemToList(
							RequestHistoryUnRead.data,
							RequestHistoryUnReadOld,
							getUserId(message),
							REQUEST_HISTORY_UNREAD_REFRESH
						)
					)
				}

				if (
					RequestHistoryReadOld === undefined &&
					RequestHistoryUnReadOld === undefined
				) {
					message.unreadMsgBadge = 1
					dispatch(
						addItemToList(
							RequestHistoryUnRead.data,
							message,
							getUserId(message),
							REQUEST_HISTORY_UNREAD_REFRESH
						)
					)
				}

				dispatch({
					type: CHAT_NUMBER_TOTAL,
					data: {
						chattingUnreadNumber: chattingUnreadNumber,
						chatedUnreadNumber:
							chatedUnreadNumber + totalRequestHistoryUnRead,
					},
				})
				callApiGetSortRequestOffline(this.props)
			} else if (message.msgType === 'EXPECTED_REQUEST_CALL') {
				playNotificationSound()
				if (RequestHistoryReadOld !== undefined) {
					dispatch(
						removeItemFromList(
							RequestHistoryRead.data,
							getUserId(message),
							REQUEST_HISTORY_READ_REFRESH
						)
					)
					RequestHistoryReadOld.unreadMsgBadge =
						RequestHistoryReadOld.unreadMsgBadge + 1
					totalRequestHistoryUnRead = totalRequestHistoryUnRead + 1
					RequestHistoryReadOld.msgType = message.msgType
					dispatch(
						addItemToList(
							RequestHistoryUnRead.data,
							RequestHistoryReadOld,
							getUserId(message),
							REQUEST_HISTORY_UNREAD_REFRESH
						)
					)
				}
				if (RequestHistoryUnReadOld !== undefined) {
					dispatch(
						removeItemFromList(
							RequestHistoryUnRead.data,
							getUserId(message),
							REQUEST_HISTORY_UNREAD_REFRESH
						)
					)
					RequestHistoryUnReadOld.unreadMsgBadge =
						RequestHistoryUnReadOld.unreadMsgBadge + 1
					totalRequestHistoryUnRead = totalRequestHistoryUnRead + 1
					RequestHistoryUnReadOld.msgType = message.msgType
					dispatch(
						addItemToList(
							RequestHistoryUnRead.data,
							RequestHistoryUnReadOld,
							getUserId(message),
							REQUEST_HISTORY_UNREAD_REFRESH
						)
					)
				}

				if (
					RequestHistoryReadOld === undefined &&
					RequestHistoryUnReadOld === undefined
				) {
					message.unreadMsgBadge = 1
					dispatch(
						addItemToList(
							RequestHistoryUnRead.data,
							message,
							getUserId(message),
							REQUEST_HISTORY_UNREAD_REFRESH
						)
					)
				}

				dispatch({
					type: CHAT_NUMBER_TOTAL,
					data: {
						chattingUnreadNumber: chattingUnreadNumber,
						chatedUnreadNumber:
							chatedUnreadNumber + totalRequestHistoryUnRead,
					},
				})
				callApiGetSortRequestOffline(this.props)
			}
		})
		socket.on('response', (res) => {
			const message = JSON.parse(res)
			console.log('%c MESSAGE: ', 'color: red', message);
			const {
				ChatRequestRead,
				ChatRequestUnRead,
				RequestHistoryUnRead,
				RequestHistoryRead,
				ChatNumberTotal,
				ChatPin, MyProfile
			} = this.props
			let ChatRequestUnReadOld =
				ChatRequestUnRead.data[getUserId(message)]
			let ChatRequestReadOld = ChatRequestRead.data[getUserId(message)]
			let chattingUnreadNumber = ChatNumberTotal.data.chattingUnreadNumber
			let chatedUnreadNumber = ChatNumberTotal.data.chatedUnreadNumber
			let RequestHistoryUnReadOld =
				RequestHistoryUnRead.data[getUserId(message)]
			let RequestHistoryReadOld =
				RequestHistoryRead.data[getUserId(message)]
			let RequestPinOld =
				ChatPin.data.find(i => getUserId(i) === getUserId(message))
			let totalChatRequestUnRead = 0
			let totalChatRequestRead = 0
			let totalRequestHistoryUnRead = 0
			let totalRequestHistoryRead = 0

			if (message.msgType === MESSAGE_TYPE_SOCKET.AUTO_MESSAGE_PICK_BEST_ANSWER) {
				dispatch(
					MyProfileActions.merge({
						isShowBestAnswerText: true,
					})
				)
			}

			if (message.fromId !== tellerId) {
				const url = window.location.href
				const messagesWithSound = [
					'CHARGE_TEXT_UNOFFICIAL',
					'SEND_FILE_UNOFFICIAL',
					'SEND_STAMP_UNOFFICIAL',
					'CHARGE_TEXT',
					'SEND_FILE',
					'SEND_STAMP',
				]
				// check is in conversation with user send this new message
				if (
					!url.includes(message.fromId) &&
					messagesWithSound.includes(message.msgType) &&
					this.state.focused === true
				) {
					if (MyProfile.data.isDisableSoundMessage && MyProfile.data.fortuneTellerStatus === fortuneTellerStatus.chatting) {

					} else {
						setTimeout(() => {
							this.sound.play()
						}, 300)
					}
				}
			}

			if (message.msgType === 'ADMIN_REPLY_SAPOTAN') {
				dispatch(ContactActions.getContactUnread(this.createUrlQuery()))
				dispatch(
					MyProfileActions.add({
						numberUnreadNotification: 1,
					})
				)
			}

			const isLoadData =
				message.msgType === 'CHARGE_TEXT' ||
				message.msgType === 'FREE_TEXT' ||
				message.msgType === 'SEND_FILE' ||
				message.msgType === 'SEND_STAMP' ||
				message.msgType === 'SEND_GIFT'

			if (
				ChatRequestUnReadOld === undefined &&
				ChatRequestReadOld === undefined &&
				isLoadData
			) {
				dispatch(
					ChatRequestActions.requestChatUnRead({
						page: 0,
						size: 24,
						inConversation: true,
					})
				)
				dispatch(
					ChatRequestActions.requestChatRead({
						page: 0,
						size: 24,
						inConversation: true,
					})
				)
				return
			}

			if (message.msgType === 'CALL') {
				if (Object.keys(this.refs).length) {
					DialogNotifi.getDialog().handleOpen('CALL', message)
				}
				this.chgCallStatus(message.endType)
			} else if (
				message.msgType === 'CHARGE_TEXT' ||
				message.msgType === 'FREE_TEXT' ||
				message.msgType === 'SEND_FILE' ||
				message.msgType === 'SEND_STAMP' ||
				message.msgType === 'SENT_FILE'
			) {
				if (this.state.userId === getUserId(message)) {
					dispatch(
						removeItemFromList(
							ChatRequestUnRead.data,
							getUserId(message),
							CHAT_REQUEST_UNREAD_REFRESH
						)
					)
					if (ChatRequestUnReadOld !== undefined) {
						ChatRequestUnReadOld.value = message.value
						ChatRequestUnReadOld.msgType = message.msgType
						ChatRequestUnReadOld.serverTime = message.serverTime
						dispatch(
							addItemToList(
								ChatRequestRead.data,
								ChatRequestUnReadOld,
								getUserId(message),
								CHAT_REQUEST_READ_REFRESH
							)
						)
					}

					if (ChatRequestReadOld !== undefined) {
						ChatRequestReadOld.value = message.value
						ChatRequestReadOld.unreadMsgBadge = 0
						ChatRequestReadOld.msgType = message.msgType
						ChatRequestReadOld.serverTime = message.serverTime
						dispatch(
							addItemToList(
								ChatRequestRead.data,
								ChatRequestReadOld,
								getUserId(message),
								CHAT_REQUEST_READ_REFRESH
							)
						)
					}
					dispatch({
						type: CHAT_NUMBER_TOTAL,
						data: {
							chattingUnreadNumber: chattingUnreadNumber,
							chatedUnreadNumber: chatedUnreadNumber,
						},
					})
				} else {
					dispatch(
						removeItemFromList(
							ChatRequestRead.data,
							getUserId(message),
							CHAT_REQUEST_READ_REFRESH
						)
					)
					if (ChatRequestReadOld !== undefined) {
						ChatRequestReadOld.value = message.value
						ChatRequestReadOld.unreadMsgBadge =
							ChatRequestReadOld.unreadMsgBadge + 1
						ChatRequestReadOld.msgType = message.msgType
						totalChatRequestUnRead = totalChatRequestUnRead + 1
						ChatRequestReadOld.serverTime = message.serverTime
						dispatch(
							addItemToList(
								ChatRequestUnRead.data,
								ChatRequestReadOld,
								getUserId(message),
								CHAT_REQUEST_UNREAD_REFRESH
							)
						)
					}

					if (ChatRequestUnReadOld !== undefined) {
						ChatRequestUnReadOld.value = message.value
						ChatRequestUnReadOld.unreadMsgBadge =
							ChatRequestUnReadOld.unreadMsgBadge + 1
						ChatRequestUnReadOld.msgType = message.msgType
						ChatRequestUnReadOld.serverTime = message.serverTime
						totalChatRequestUnRead = totalChatRequestUnRead + 1
						ChatRequestUnReadOld.serverTime = message.serverTime
						dispatch(
							addItemToList(
								ChatRequestUnRead.data,
								ChatRequestUnReadOld,
								getUserId(message),
								CHAT_REQUEST_UNREAD_REFRESH
							)
						)
					}
				}

				dispatch({
					type: CHAT_NUMBER_TOTAL,
					data: {
						chattingUnreadNumber:
							chattingUnreadNumber + totalChatRequestUnRead,
						chatedUnreadNumber: chatedUnreadNumber,
					},
				})

				// if (message.msgType === "SEND_FILE") {
				//   dispatch(MyProfileActions.add({
				//     numberUnreadNotification: 1
				//   }))
				// }
			} else if (message.msgType === 'SENT_TEXT') {
				if (ChatRequestReadOld !== undefined) {
					dispatch(
						removeItemFromList(
							ChatRequestRead.data,
							getUserId(message),
							CHAT_REQUEST_READ_REFRESH
						)
					)
					ChatRequestReadOld.value = message.value
					ChatRequestReadOld.msgType = message.msgType
					ChatRequestReadOld.serverTime = message.serverTime
					dispatch(
						addItemToList(
							ChatRequestRead.data,
							ChatRequestReadOld,
							getUserId(message),
							CHAT_REQUEST_READ_REFRESH
						)
					)
				}

				if (ChatRequestUnReadOld !== undefined) {
					dispatch(
						removeItemFromList(
							ChatRequestUnRead.data,
							getUserId(message),
							CHAT_REQUEST_UNREAD_REFRESH
						)
					)
					ChatRequestUnReadOld.value = message.value
					ChatRequestUnReadOld.msgType = message.msgType
					ChatRequestUnReadOld.serverTime = message.serverTime
					dispatch(
						addItemToList(
							ChatRequestUnRead.data,
							ChatRequestUnReadOld,
							getUserId(message),
							CHAT_REQUEST_UNREAD_REFRESH
						)
					)
				}
				dispatch({
					type: CHAT_NUMBER_TOTAL,
					data: {
						chattingUnreadNumber:
							chattingUnreadNumber + totalChatRequestUnRead,
						chatedUnreadNumber: chatedUnreadNumber,
					},
				})
			} else if (
				message.msgType === 'CHARGE_TEXT_UNOFFICIAL' ||
				message.msgType === 'SEND_FILE_UNOFFICIAL' ||
				message.msgType === 'SEND_STAMP_UNOFFICIAL' ||
				message.msgType === 'FREE_TEXT_UNOFFICIAL'
			) {
				if (
					ChatRequestReadOld !== undefined ||
					ChatRequestUnReadOld !== undefined
				) {
					if (this.state.userId === getUserId(message)) {
						if (ChatRequestReadOld !== undefined) {
							dispatch(
								removeItemFromList(
									ChatRequestRead.data,
									getUserId(message),
									CHAT_REQUEST_READ_REFRESH
								)
							)
							ChatRequestReadOld.value = message.value
							ChatRequestReadOld.unreadMsgBadge = 0
							ChatRequestReadOld.msgType = message.msgType
							ChatRequestReadOld.serverTime = message.serverTime
							dispatch(
								addItemToList(
									ChatRequestRead.data,
									ChatRequestReadOld,
									getUserId(message),
									CHAT_REQUEST_READ_REFRESH
								)
							)
						}
					} else {
						dispatch(
							removeItemFromList(
								ChatRequestRead.data,
								getUserId(message),
								CHAT_REQUEST_READ_REFRESH
							)
						)

						if (ChatRequestUnReadOld !== undefined) {
							dispatch(
								removeItemFromList(
									ChatRequestUnRead.data,
									getUserId(message),
									CHAT_REQUEST_UNREAD_REFRESH
								)
							)
							ChatRequestUnReadOld.value = message.value
							ChatRequestUnReadOld.unreadMsgBadge =
								ChatRequestUnReadOld.unreadMsgBadge + 1
							ChatRequestUnReadOld.msgType = message.msgType
							totalChatRequestUnRead = totalChatRequestUnRead + 1
							ChatRequestUnReadOld.serverTime = message.serverTime
							dispatch(
								addItemToList(
									ChatRequestUnRead.data,
									ChatRequestUnReadOld,
									getUserId(message),
									CHAT_REQUEST_UNREAD_REFRESH
								)
							)
						}
						if (ChatRequestReadOld) {
							dispatch(
								removeItemFromList(
									ChatRequestRead.data,
									getUserId(message),
									CHAT_REQUEST_READ_REFRESH
								)
							)
							ChatRequestReadOld.value = message.value
							ChatRequestReadOld.unreadMsgBadge = 1
							ChatRequestReadOld.msgType = message.msgType
							totalChatRequestUnRead =
								totalChatRequestUnRead +
								ChatRequestReadOld.unreadMsgBadge
							ChatRequestReadOld.serverTime = message.serverTime
							dispatch(
								addItemToList(
									ChatRequestUnRead.data,
									ChatRequestReadOld,
									getUserId(message),
									CHAT_REQUEST_UNREAD_REFRESH
								)
							)
						}
					}
				} else {
					if (
						RequestHistoryReadOld !== undefined ||
						RequestHistoryUnReadOld !== undefined ||
						RequestPinOld !== undefined
					) {
						if (this.state.userId === getUserId(message)) {
							if (RequestHistoryReadOld !== undefined) {
								dispatch(
									removeItemFromList(
										RequestHistoryRead.data,
										getUserId(message),
										REQUEST_HISTORY_READ_REFRESH
									)
								)
								RequestHistoryReadOld.value = message.value
								RequestHistoryReadOld.unreadMsgBadge = 0
								RequestHistoryReadOld.msgType = message.msgType
								RequestHistoryReadOld.serverTime = message.serverTime
								dispatch(
									addItemToList(
										RequestHistoryRead.data,
										RequestHistoryReadOld,
										getUserId(message),
										REQUEST_HISTORY_READ_REFRESH
									)
								)
							}
							if (RequestPinOld !== undefined) {
								RequestPinOld.value = message.value
								RequestPinOld.unreadMsgBadge = 0
								RequestPinOld.msgType = message.msgType
								RequestPinOld.serverTime = message.serverTime
								dispatch(RequestHistoryActions.refreshItemPin({ message: RequestPinOld }))
							}
						} else {
							dispatch(
								removeItemFromList(
									RequestHistoryRead.data,
									getUserId(message),
									REQUEST_HISTORY_READ_REFRESH
								)
							)
							dispatch(
								removeItemFromList(
									RequestHistoryUnRead.data,
									getUserId(message),
									REQUEST_HISTORY_UNREAD_REFRESH
								)
							)
							if (RequestPinOld !== undefined) {
								RequestPinOld.value = message.value
								RequestPinOld.unreadMsgBadge = RequestPinOld.unreadMsgBadge + 1
								RequestPinOld.msgType = message.msgType
								RequestPinOld.serverTime = message.serverTime
								dispatch(RequestHistoryActions.refreshItemPin({ message: RequestPinOld }))
							}
							if (RequestHistoryUnReadOld !== undefined) {
								RequestHistoryUnReadOld.value = message.value
								RequestHistoryUnReadOld.unreadMsgBadge =
									RequestHistoryUnReadOld.unreadMsgBadge + 1
								RequestHistoryUnReadOld.msgType =
									message.msgType
								totalRequestHistoryUnRead =
									totalRequestHistoryUnRead + 1
								RequestHistoryUnReadOld.serverTime = message.serverTime
								dispatch(
									addItemToList(
										RequestHistoryUnRead.data,
										RequestHistoryUnReadOld,
										getUserId(message),
										REQUEST_HISTORY_UNREAD_REFRESH
									)
								)
							}
							if (RequestHistoryReadOld) {
								RequestHistoryReadOld.value = message.value
								RequestHistoryReadOld.unreadMsgBadge = 1
								RequestHistoryReadOld.msgType = message.msgType
								totalRequestHistoryUnRead = 1
								RequestHistoryReadOld.serverTime = message.serverTime
								dispatch(
									addItemToList(
										RequestHistoryUnRead.data,
										RequestHistoryReadOld,
										getUserId(message),
										REQUEST_HISTORY_UNREAD_REFRESH
									)
								)
							}
						}
						/*dispatch({type: CHAT_NUMBER_TOTAL, data: {chattingUnreadNumber: chattingUnreadNumber,
				catedUnreadNumber: chatedUnreadNumber + totalRequestHistoryUnRead}})*/
					}
				}
				if (totalRequestHistoryUnRead == 0 && message.fromId !== RequestHistoryUnRead.checkUnread && message.fromId !== this.props.MyProfile.data.fortuneTellerId) {
					totalRequestHistoryUnRead = 1
				}
				dispatch({
					type: CHAT_NUMBER_TOTAL,
					data: {
						chattingUnreadNumber:
							chattingUnreadNumber + totalChatRequestUnRead,
						chatedUnreadNumber:
							chatedUnreadNumber + totalRequestHistoryUnRead,
					},
				})

				if (message.msgType === 'SEND_GIFT') {
					message.point = message.giftPrice
					DialogNotifi.getDialog().handleOpen('GIFT', message)
				}
				// if (message.msgType === "SEND_FILE_UNOFFICIAL") {
				//   dispatch(MyProfileActions.add({
				//     numberUnreadNotification: 1
				//   }))
				// }
			} else if (message.msgType === 'SEND_GIFT') {
				if (
					ChatRequestReadOld !== undefined ||
					ChatRequestUnReadOld !== undefined
				) {
					if (this.state.userId === getUserId(message)) {
						if (ChatRequestReadOld !== undefined) {
							dispatch(
								removeItemFromList(
									ChatRequestRead.data,
									getUserId(message),
									CHAT_REQUEST_READ_REFRESH
								)
							)
							ChatRequestReadOld.value = message.value
							ChatRequestReadOld.unreadMsgBadge = 0
							ChatRequestReadOld.msgType = message.msgType
							ChatRequestReadOld.serverTime = message.serverTime
							dispatch(
								addItemToList(
									ChatRequestRead.data,
									ChatRequestReadOld,
									getUserId(message),
									CHAT_REQUEST_READ_REFRESH
								)
							)
						}
					} else {
						dispatch(
							removeItemFromList(
								ChatRequestRead.data,
								getUserId(message),
								CHAT_REQUEST_READ_REFRESH
							)
						)
						if (ChatRequestUnReadOld !== undefined) {
							ChatRequestUnReadOld.value = message.value
							// ChatRequestUnReadOld.unreadMsgBadge = ChatRequestUnReadOld.unreadMsgBadge + 1;
							ChatRequestUnReadOld.msgType = message.msgType
							// totalChatRequestUnRead = totalChatRequestUnRead + 1;
							ChatRequestUnReadOld.serverTime = message.serverTime
							dispatch(
								addItemToList(
									ChatRequestUnRead.data,
									ChatRequestUnReadOld,
									getUserId(message),
									CHAT_REQUEST_UNREAD_REFRESH
								)
							)
						}
						if (ChatRequestReadOld) {
							ChatRequestReadOld.value = message.value
							ChatRequestReadOld.unreadMsgBadge = 0
							ChatRequestReadOld.msgType = message.msgType
							totalChatRequestUnRead =
								totalChatRequestUnRead +
								ChatRequestReadOld.unreadMsgBadge
							ChatRequestReadOld.serverTime = message.serverTime
							dispatch(
								addItemToList(
									ChatRequestUnRead.data,
									ChatRequestReadOld,
									getUserId(message),
									CHAT_REQUEST_UNREAD_REFRESH
								)
							)
						}
					}
					dispatch({
						type: CHAT_NUMBER_TOTAL,
						data: {
							chattingUnreadNumber:
								chattingUnreadNumber + totalChatRequestUnRead,
							chatedUnreadNumber: chatedUnreadNumber,
						},
					})
				} else {
					if (
						RequestHistoryReadOld !== undefined ||
						RequestHistoryUnReadOld !== undefined
					) {
						if (this.state.userId === getUserId(message)) {
							if (RequestHistoryReadOld !== undefined) {
								RequestHistoryReadOld.value = message.value
								RequestHistoryReadOld.unreadMsgBadge = 0
								RequestHistoryReadOld.msgType = message.msgType
								dispatch(
									addItemToList(
										RequestHistoryRead.data,
										message,
										getUserId(message),
										REQUEST_HISTORY_READ_REFRESH
									)
								)
							}
						} else {
							dispatch(
								removeItemFromList(
									RequestHistoryRead.data,
									getUserId(message),
									REQUEST_HISTORY_READ_REFRESH
								)
							)
							if (RequestHistoryUnReadOld !== undefined) {
								RequestHistoryUnReadOld.value = message.value
								// RequestHistoryUnReadOld.unreadMsgBadge = RequestHistoryUnReadOld.unreadMsgBadge + 1;
								RequestHistoryUnReadOld.msgType =
									message.msgType
								// totalRequestHistoryUnRead = totalRequestHistoryUnRead + 1;
								dispatch(
									addItemToList(
										RequestHistoryUnRead.data,
										RequestHistoryUnReadOld,
										getUserId(message),
										REQUEST_HISTORY_UNREAD_REFRESH
									)
								)
							}
							if (RequestHistoryReadOld) {
								RequestHistoryReadOld.value = message.value
								RequestHistoryReadOld.unreadMsgBadge = 0
								RequestHistoryReadOld.msgType = message.msgType
								totalRequestHistoryUnRead = 0
								dispatch(
									addItemToList(
										RequestHistoryUnRead.data,
										RequestHistoryReadOld,
										getUserId(message),
										REQUEST_HISTORY_UNREAD_REFRESH
									)
								)
							}
						}
						dispatch({
							type: CHAT_NUMBER_TOTAL,
							data: {
								chattingUnreadNumber: chattingUnreadNumber,
								chatedUnreadNumber:
									chatedUnreadNumber +
									totalRequestHistoryUnRead,
							},
						})
					}
				}

				if (message.msgType === 'SEND_GIFT') {
					message.point = message.giftPrice
					DialogNotifi.getDialog().handleOpen('GIFT', message)
				}
				if (message.msgType === 'SEND_FILE_UNOFFICIAL') {
					dispatch(
						MyProfileActions.add({
							numberUnreadNotification: 1,
						})
					)
				}
			} else if (message.msgType === 'CANCEL_EXPECTED_REQUEST_CHAT') {
				playNotificationSound()
				let messageOldUser = {
					msgId: message.msgId,
					fromId: message.fromId,
					toId: message.toId,
					type: 1,
					msgType: message.msgType,
					serverTime: message.serverTime,
					userName: message.userName,
					unreadMsgBadge: 1,
				}

				if (RequestHistoryReadOld !== undefined) {
					dispatch(
						removeItemFromList(
							RequestHistoryRead.data,
							getUserId(message),
							REQUEST_HISTORY_READ_REFRESH
						)
					)
					RequestHistoryReadOld.unreadMsgBadge =
						RequestHistoryReadOld.unreadMsgBadge + 1
					RequestHistoryReadOld.msgType =
						'CANCEL_EXPECTED_REQUEST_CHAT'
					totalRequestHistoryUnRead = totalRequestHistoryUnRead + 1
					dispatch(
						addItemToList(
							RequestHistoryUnRead.data,
							RequestHistoryReadOld,
							getUserId(message),
							REQUEST_HISTORY_UNREAD_REFRESH
						)
					)
				}

				if (RequestHistoryUnReadOld !== undefined) {
					RequestHistoryUnReadOld.unreadMsgBadge =
						RequestHistoryUnReadOld.unreadMsgBadge + 1
					RequestHistoryUnReadOld.msgType =
						'CANCEL_EXPECTED_REQUEST_CHAT'
					totalRequestHistoryUnRead = totalRequestHistoryUnRead + 1
					dispatch(
						addItemToList(
							RequestHistoryUnRead.data,
							RequestHistoryUnReadOld,
							getUserId(message),
							REQUEST_HISTORY_UNREAD_REFRESH
						)
					)
				}

				dispatch(RequestOfflineActions.del(getUserId(message)))
				// dispatch(RequestOfflineActions.merge({userId: message.fromId, data: messageOldUser}));
				dispatch({
					type: CHAT_NUMBER_TOTAL,
					data: {
						chattingUnreadNumber: chattingUnreadNumber,
						chatedUnreadNumber:
							chatedUnreadNumber + totalRequestHistoryUnRead,
					},
				})
			} else if (message.msgType === 'CANCEL_EXPECTED_REQUEST_CALL') {
				playNotificationSound()
				let messageOldUser = {
					msgId: message.msgId,
					fromId: message.fromId,
					toId: message.toId,
					type: 1,
					msgType: message.msgType,
					serverTime: message.serverTime,
					userName: message.userName,
					unreadMsgBadge: 1,
				}

				if (RequestHistoryReadOld !== undefined) {
					dispatch(
						removeItemFromList(
							RequestHistoryRead.data,
							getUserId(message),
							REQUEST_HISTORY_READ_REFRESH
						)
					)
					RequestHistoryReadOld.unreadMsgBadge =
						RequestHistoryReadOld.unreadMsgBadge + 1
					RequestHistoryReadOld.msgType =
						'CANCEL_EXPECTED_REQUEST_CALL'
					totalRequestHistoryUnRead = totalRequestHistoryUnRead + 1
					dispatch(
						addItemToList(
							RequestHistoryUnRead.data,
							RequestHistoryReadOld,
							getUserId(message),
							REQUEST_HISTORY_UNREAD_REFRESH
						)
					)
				}

				if (RequestHistoryUnReadOld !== undefined) {
					RequestHistoryUnReadOld.unreadMsgBadge =
						RequestHistoryUnReadOld.unreadMsgBadge + 1
					RequestHistoryUnReadOld.msgType =
						'CANCEL_EXPECTED_REQUEST_CALL'
					totalRequestHistoryUnRead = totalRequestHistoryUnRead + 1
					dispatch(
						addItemToList(
							RequestHistoryUnRead.data,
							RequestHistoryUnReadOld,
							getUserId(message),
							REQUEST_HISTORY_UNREAD_REFRESH
						)
					)
				}

				dispatch(RequestOfflineActions.del(getUserId(message)))
				// dispatch(RequestOfflineActions.merge({userId: message.fromId, data: messageOldUser}));
				dispatch({
					type: CHAT_NUMBER_TOTAL,
					data: {
						chattingUnreadNumber: chattingUnreadNumber,
						chatedUnreadNumber:
							chatedUnreadNumber + totalRequestHistoryUnRead,
					},
				})
			} else if (message.msgType === 'POPUP_RANKING_EVENT') {
				console.log(this.props.location.pathname)
				if (this.props.location.pathname === '/home' && message.status === STATUS_EVENT_RANKING.PUBLISHED) {
					DialogNotifi.getDialog().handleOpen(
						'EVENT_RANKING',
						{ item: message }
					)
					dispatch(RankingEventActions.getAllRankingEvent())
				}
			}
		})

		socket.on('tellerNoAction', (res) => {
			const message = JSON.parse(res)
			console.log('--- CHANGE STATUS ---', message)
			// Change status
			const { MyProfile } = this.props
			if (
				message.msgType === 'CHANGE_FORTUNE_TELLER_STATUS' &&
				MyProfile.data.fortuneTellerId === message.fromId
			) {
				dispatch(
					MyProfileActions.merge({
						fortuneTellerStatus: message.newStatus,
					})
				)
				pauseNotificationSound()
				this.fetchProfileDebounce()
			}

			// Show idle popup
			if (message.msgType === 'NO_ACTION_SHOW_POPUP') {
				console.log('-----NO_ACTION_SHOW_POPUP-----', message)
				dispatch(IdleActions.showPopup())
			}

			// Sync Yes option on idle popup
			if (message.msgType === 'NO_ACTION_STAY_WAITTING_CONFIRM_SYN') {
				// Yes sync
				console.log('--- NO_ACTION_STAY_WAITTING_CONFIRM_SYN')
				// Hide idle popup
				dispatch(IdleActions.syncContinueWaiting())
			}

			// Sync No option on idle popup
			if (message.msgType === 'NO_ACTION_OFFLINE_CONFIRM_SYN') {
				// No sync
				console.log('--- NO_ACTION_OFFLINE_CONFIRM_SYN')
				dispatch(
					MyProfileActions.merge({
						['fortuneTellerStatus']: fortuneTellerStatus.offline,
					})
				)
				// Hide idle popup
				dispatch(IdleActions.syncFinishWaiting())
			}
		})
	}

	/**
	 * 通話通知のendTypeによってMyProfileのstatusを更新
	 * @param {string} endType (着信中:INCALL, キャンセル:CANCEL, 通話中:BUSY, 切断: CLOSE)
	 */
	chgCallStatus(endType) {
		const { dispatch } = this.props
		switch (endType) {
			case 'BUSY': {
				dispatch(
					MyProfileActions.merge({
						fortuneTellerStatus: fortuneTellerStatus.calling,
					})
				)
				break
			}
			case 'CLOSE': {
				this.fetchProfileDebounce()
				break
			}
			default: {
				break
			}
		}
	}

	/**
	 * バッジがあるタブをクリックした時のイベントハンドラー
	 * @param  kind
	 */
	handeleBadge(kind) {
		const { dispatch, MyProfile, AnswerManagement } = this.props
		//badgeを0にする
		if (MyProfile.data[kind] !== 0) {
			dispatch(
				MyProfileActions.merge({
					[kind]: 0,
				})
			)
		}

		//通知ログを表示
		if (kind === 'numberUnreadNotification') {
			this.setState({
				showNotifiLog: !this.state.showNotifiLog,
			})
		}
	}

	checkDialogWelcomeStatus = () => {
		const { MyProfile, dispatch } = this.props
		this.checkModalMissingAnswer()
		if (this.state.onClickAnswer) {
			if (MyProfile.data.isHiddenStellaAnswerPopup) {
				dispatch(AnswerManagementActions.closeDialogWelComeAnswer())
			} else {
				dispatch(AnswerManagementActions.openDialogWelComeAnswer())
			}
		}
	}

	createUrlQuery() {
		const { MyProfile } = this.props
		let params = [
			`userId=${MyProfile.data.fortuneTellerId}`,
			'size=99',
			`page=0`,
			`readflg=0`,
		]
		return `?${params.join('&')}`
	}

	render() {
		const { MyProfile, bottomItemActive, AnswerManagement } = this.props
		/*const logoStyle = {
		backgroundImage: `url(/img/logo.png)`,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "60pt 5pt",
	}*/

		const homeIcon = (
			<FontIcon
				className="material-icons"
				style={
					bottomItemActive === 1 ? { color: 'rgb(17,173,157)' } : {}
				}
			>
				home
			</FontIcon>
		)
		const assignmentIcon = (
			<i
				className="fas fa-file-alt"
				style={
					bottomItemActive === 2 ? { color: 'rgb(17,173,157)' } : {}
				}
			/>
		)
		const customerIcon = (
			<FontIcon
				className="material-icons"
				style={
					bottomItemActive === 3 ? { color: 'rgb(17,173,157)' } : {}
				}
			>
				person
			</FontIcon>
		)
		const pointIcon = (
			<i
				className="fab fa-product-hunt"
				style={
					bottomItemActive === 4 ? { color: 'rgb(17,173,157)' } : {}
				}
			/>
		)
		const menuIcon = (
			<FontIcon
				className="material-icons"
				style={
					bottomItemActive === 5 ? { color: 'rgb(17,173,157)' } : {}
				}
			>
				menu
			</FontIcon>
		)
		const classMenuCollapse = window.location.pathname.includes('/chat')
			? 'menu-collapse-chat'
			: 'menu-collapse'

		return (
			<div>
				<Navbar fixedBottom fluid className="menu-header">
					<Row>
						<Navbar.Header style={stylesNav_header} ref="navBar">
							<Col
								xs={12}
								lgHidden
								mdHidden
								smHidden
								className="navtab-header"
							>
								<Link
									to="/home"
									className="btn-tab global-header__home"
									data-badge="0"
								>
									<BottomNavigationItem
										label="ホーム"
										icon={homeIcon}
										className={
											bottomItemActive === 1
												? 'active-bottom-item'
												: ''
										}
									/>
								</Link>
								<Link
									to="/home/timeline"
									className="btn-tab global-header__timeline"
									data-badge="0"
									role="button"
								>
									<BottomNavigationItem
										label="タイムライン"
										icon={assignmentIcon}
										className={
											bottomItemActive === 2
												? 'active-bottom-item'
												: ''
										}
									/>
								</Link>

								<Link
									to="/home/customermanagement"
									className="btn-tab global-header__management"
									data-badge="0"
									role="button"
								>
									<BottomNavigationItem
										label="顧客管理"
										icon={customerIcon}
										className={
											bottomItemActive === 3
												? 'active-bottom-item'
												: ''
										}
									/>
								</Link>
								<Link
									to="/home/reward"
									className="btn-tab global-header__checkout"
									data-badge="0"
									role="button"
								>
									<BottomNavigationItem
										label="精算"
										icon={pointIcon}
										className={
											bottomItemActive === 4
												? 'active-bottom-item'
												: ''
										}
									/>
								</Link>
								<Link
									to="/home/menu"
									className="btn-tab global-header__menu"
									data-badge="0"
									role="button"
								>
									<BottomNavigationItem
										label="メニュー"
										icon={menuIcon}
										className={
											bottomItemActive === 5
												? 'active-bottom-item'
												: ''
										}
									/>
								</Link>
							</Col>
							<Col xsHidden>
								<Navbar.Brand>
									<Link className="sitelogo" to="/home">
										<img
											className="sitelogo__image"
											src={'/img/logo.png'}
											alt={''}
										/>
									</Link>
								</Navbar.Brand>
							</Col>
						</Navbar.Header>
						<Navbar.Collapse className={classMenuCollapse}>
							<Nav>
								<li role="presentation">
									<Link
										to="/home"
										className="btn-tab global-header__home"
										data-badge="0"
									>
										ホーム
									</Link>
								</li>
								<li role="presentation">
									<Link
										to="/home/timeline"
										className="btn-tab global-header__timeline"
										data-badge="0"
										role="button"
									>
										タイムライン
									</Link>
								</li>
								<li
									role="presentation"
									onClick={this.checkDialogWelcomeStatus}
								>
									{
										!isPWA() ?
											<div className="btn-tab global-header__answer event-click">
												教えて！先生
											</div>
											:
											<Link
												to="/home/answer"
												className="btn-tab global-header__answer"
												data-badge={
													AnswerManagement.numberUnreadBestAnswer
												}
												role="button"
											>
												教えて！先生
											</Link>
									}
								</li>
								<li role="presentation">
									<Link
										to="/home/customermanagement"
										className="btn-tab global-header__management"
										data-badge="0"
										role="button"
									>
										顧客管理
									</Link>
								</li>
								<li role="presentation">
									<Link
										to="/home/reward"
										className="btn-tab global-header__checkout"
										data-badge="0"
										role="button"
									>
										精算
									</Link>
								</li>
								<li role="presentation">
									<Link
										to="/home/review"
										onClick={() =>
											this.handeleBadge(
												'numberUnreadReview'
											)
										}
										className="btn-tab global-header__review"
										role="button"
										data-badge={
											MyProfile.data.numberUnreadReview
										}
									>
										レビュー
									</Link>
								</li>
								<li role="presentation">
									<Link
										to={`/home/analysis/point-report`}
										className="btn-tab global-header__settings"
										data-badge="0"
										role="button"
									>
										レポート{' '}
									</Link>
								</li>
								<li role="presentation">
									<Link
										to={{
											pathname: "/home/event",
											params: {
												index: 0
											}
										}}
										className="btn-tab global-header__review"
										role="button"
										data-badge={
											this.props.MyProfile.data
												.isClickTabPointReport
												? null
												: 'N'
										}
									>
										イベント
									</Link>
								</li>

								<li role="presentation">
									<Link
										to="/home/setting"
										className="btn-tab global-header__settings"
										data-badge={
											this.props.MyProfile.data
												.isHiddenNewSampleVoice
												? null
												: 'N'
										}
										role="button"
									>
										設定
									</Link>
								</li>
								<li role="presentation">
									<Link
										to="/home/introduction"
										className="btn-tab global-header__settings"
										data-badge="0"
										role="button"
									>
										紹介
									</Link>
								</li>
								<li role="presentation">
									<span
										onClick={() =>
											this.handeleBadge(
												'numberUnreadNotification'
											)
										}
										role="button"
										className="btn-tab global-header__notice"
										data-badge={
											MyProfile.data
												.numberUnreadNotification > 99
												? '99+'
												: MyProfile.data
													.numberUnreadNotification
										}
									>
										通知
									</span>
								</li>
								<li role="presentation">
									<Link
										to="/home/help"
										className="btn-tab global-header__help"
										role="button"
										data-badge="0"
									>
										ヘルプ
									</Link>
								</li>
							</Nav>
							<Nav pullRight>
								<li role="presentation">
									<a
										href="javascript:void(0)"
										onClick={() =>
											this.setState({
												showNextLogin:
													!this.state.showNextLogin,
											})
										}
										className="btn-tab global-header__logout"
										role="button"
									>
										ログアウト
									</a>
								</li>
							</Nav>
						</Navbar.Collapse>
					</Row>
				</Navbar>
				<LogoutDialog
					show={this.state.showNextLogin}
					closeFunc={() => this.setState({ showNextLogin: false })}
				/>
				<HeaderNotifi
					show={this.state.showNotifiLog}
					closeFunc={() =>
						this.handeleBadge('numberUnreadNotification')
					}
				/>
				<DialogWelcomeAnswer dispatch={this.props.dispatch} />
				<ModalMissingAnswer
					show={this.state.showModalMissingAnswer}
					resetToggle={this.resetToggleMissing}
				/>
			</div>
		)
	}
}

/*const styleText = {
	fontSize: '12px',
	transition: 'color 0.3s, font-size 0.3s',
	color: 'rgba(0, 0, 0, 0.54)',
}*/

const stylesNav_header = {
	width: '256px',
	height: '100%',
	backgroundColor: '#2a2a3b',
}

const customLogoutDialogStyle = {
	width: '485px',
}

const LogoutDialog = ({ show, closeFunc }) => {
	if (!show) {
		return null
	}

	return (
		<Dialog
			open={true}
			modal={false}
			title="ログアウト"
			onRequestClose={() => closeFunc()}
			bodyClassName="dialog__content"
			contentStyle={customLogoutDialogStyle}
		>
			<p className="logout-dialog__text">
				次回のログイン予定を登録しておくと相談件数が向上します。
			</p>
			<NextLogin
				show={true}
				place="logout"
				closeFunc={() => closeFunc()}
			/>
		</Dialog>
	)
}

const mapStateToProps = (state) => {
	return {
		MyProfile: state.MyProfile,
		ChatRequest: state.ChatRequest,
		IdleManagement: state.IdleManagement,
		ChatNumberTotal: state.ChatNumberTotal,
		ChatRequestUnRead: state.ChatRequestUnRead,
		ChatRequestRead: state.ChatRequestRead,
		RequestHistoryUnRead: state.RequestHistoryUnRead,
		RequestHistoryRead: state.RequestHistoryRead,
		ChatPin: state.RequestHistoryPin
	}
}

export default connect(mapStateToProps)(Header)

/**
 * actionキー
 * actions とreducersの更新も忘れないでね
 */

//ログイン
export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_TELLERID = 'AUTH_TELLERID'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'

//MyInfo系
export const MYINFO_PROFILE = 'MYINFO_PROFILE'
export const MYINFO_PROFILE_PUT = 'MYINFO_PROFILE_PUT'
export const MYINFO_PROFILE_PUTTING = 'MYINFO_PROFILE_PUTTING'
export const MYINFO_PROFILE_MERGE = 'MYINFO_PROFILE_MERGE'
export const MYINFO_PROFILE_ADD = 'MYINFO_PROFILE_ADD'
export const LIST_REASON_DENY_AVATAR = 'LIST_REASON_DENY_AVATAR'

//通知ログ
export const MYINFO_NOTIFICATION = 'MYINFO_NOTIFICATION'

//account系
export const MYINFO_ACCOUNT = 'MYINFO_ACCOUNT'
export const MYINFO_ACCOUNT_PUT = 'MYINFO_ACCOUNT_PUT'
export const MYINFO_ACCOUNT_PUT_FALSE = 'MYINFO_ACCOUNT_PUT_FALSE'
export const MYINFO_TEMPLATES = 'MYINFO_TEMPLATES'
export const MYINFO_ACCOUNT_PASSWORD_PUT = 'MYINFO_ACCOUNT_PASSWORD_PUT'
export const MYINFO_ACCOUNT_AGE_AUTH_POST = 'MYINFO_ACCOUNT_AGE_AUTH_POST'

//template系
export const MYINFO_TEMPLATES_PUT = 'MYINFO_TEMPLATES_PUT'

//MyInfo新規作成系
export const MYINFO_AVATAR_POST = 'MYINFO_AVATAR_POST'
export const MYINFO_TEMPLATES_POST = 'MYINFO_TEMPLATES_POST'
export const MYINFO_TEMPLATES_DELETE = 'MYINFO_TEMPLATES_DELETE'

//次回ログイン予定系
export const NEXTLOGIN_PUT = 'NEXTLOGIN_PUT'

//相談申し込み
export const CHAT_REQUEST = 'CHAT_REQUEST'
export const CHAT_REQUEST_NEW = 'CHAT_REQUEST_NEW'
export const CHAT_REQUEST_UNREAD = 'CHAT_REQUEST_UNREAD'
export const CHAT_REQUEST_UNREAD_REFRESH = 'CHAT_REQUEST_UNREAD_REFRESH'
export const CHAT_REQUEST_READ = 'CHAT_REQUEST_READ'
export const CHAT_REQUEST_READ_REFRESH = 'CHAT_REQUEST_READ_REFRESH'
export const CHAT_REQUEST_DEFAULT = 'CHAT_REQUEST_DEFAULT'
export const CHAT_REQUEST_ANSWER = 'CHAT_REQUEST_ANSWER'
export const CHAT_REQUEST_MERGE = 'CHAT_REQUEST_MERGE'
export const CHAT_REQUEST_DELETE = 'CHAT_REQUEST_DELETE'
export const ADD_CHAT_DRAFT = 'ADD_CHAT_DRAFT'
export const CHANGE_STATUS_CHAT_DRAFT = 'CHANGE_STATUS_CHAT_DRAFT'

export const CHAT_NUMBER_TOTAL = 'CHAT_NUMBER_TOTAL'

//チャット(相談)リクエスト（オフライン）
export const REQUEST_OFFLINE = 'REQUEST_OFFLINE'
export const REQUEST_OFFLINE_MERGE = 'REQUEST_OFFLINE_MERGE'
export const REQUEST_OFFLINE_DELETE = 'REQUEST_OFFLINE_DELETE'

//リクエスト履歴
export const REQUEST_HISTORY = 'REQUEST_HISTORY'
export const REQUEST_HISTORY_UNREAD = 'REQUEST_HISTORY_UNREAD'
export const REFRESH_HISTORY_UNREAD_UN_PIN = 'REFRESH_HISTORY_UNREAD_UN_PIN'
export const REQUEST_HISTORY_PIN = 'REQUEST_HISTORY_PIN'
export const REQUEST_PIN_CHAT = 'REQUEST_PIN_CHAT'
export const REFRESH_ITEM_PIN_CHAT = 'REFRESH_ITEM_PIN_CHAT'
export const REQUEST_UNPIN_CHAT = 'REQUEST_UNPIN_CHAT'
export const REQUEST_HISTORY_UNREAD_CHECK = 'REQUEST_HISTORY_UNREAD_CHECK'
export const REQUEST_HISTORY_UNREAD_REFRESH = 'REQUEST_HISTORY_UNREAD_REFRESH'
export const REQUEST_HISTORY_READ = 'REQUEST_HISTORY_READ'
export const RESET_REQUEST_HISTORY = 'RESET_REQUEST_HISTORY'
export const REQUEST_HISTORY_READ_REFRESH = 'REQUEST_HISTORY_READ_REFRESH'
export const REQUEST_HISTORY_READ_DEFAULT = 'REQUEST_HISTORY_READ_DEFAULT'
export const REQUEST_HISTORY_UNREAD_DEFAULT = 'REQUEST_HISTORY_UNREAD_DEFAULT'
export const REQUEST_HISTORY_MERGE = 'REQUEST_HISTORY_MERGE'
export const REQUEST_DELETE_MESSAGE_UNREAD = 'REQUEST_DELETE_MESSAGE_UNREAD'
export const REQUEST_DELETE_MESSAGE_READ = 'REQUEST_DELETE_MESSAGE_READ'
export const REFRESH_HISTORY_READ_UN_PIN = 'REFRESH_HISTORY_READ_UN_PIN'

//フォロワー
export const FOLLOWERS_GET = 'FOLLOWERS_GET'

//ユーザー情報
export const USER_INFO_FETCH = 'USER_INFO_FETCH'
export const USER_INFO_POST = 'USER_INFO_POST'
export const USER_INFO_MERGE = 'USER_INFO_MERGE'

//サイトからのお知らせ
export const NEWS_FETCH = 'NEWS_FETCH'
//スタッフブログ
export const STAFFBLOG_FETCH = 'STAFFBLOG_FETCH'

//ファイル
export const FILE_UPLOAD_POST = 'FILE_UPLOAD_POST'
export const FILE_UPLOAD_GET = 'FILE_UPLOAD_GET'
export const FILE_UPLOAD_PUT = 'FILE_UPLOAD_PUT'
export const FILE_UPLOAD_DELETE = 'FILE_UPLOAD_DELETE'
export const FILE_CONVERT_CANVAS_TO_IMAGE_GET =
	'FILE_CONVERT_CANVAS_TO_IMAGE_GET'

//テンプレート
export const TEMPLATE_POST = 'TEMPLATE_POST'
export const TEMPLATE_GET = 'TEMPLATE_GET'
export const TEMPLATE_PUT = 'TEMPLATE_PUT'
export const TEMPLATE_DELETE = 'TEMPLATE_DELETE'
export const TEMPLATE_PUT_LOADING = 'TEMPLATE_PUT_LOADING'

//銀行
export const BANKS_GET = 'BANKS_GET'
export const BANK_GET = 'BANK_GET'
export const BANK_POST = 'BANK_POST'
export const BANK_BACK = 'BANK_BACK'
export const BANK_BRANCHS_GET = 'BANK_BRANCHS_GET'
export const BANK_BRANCH_GET = 'BANK_BRANCH_GET'
export const BANK_ACCOUNT_EDIT = 'BANK_ACCOUNT_EDIT'
export const BANK_BRANCH_POST = 'BANK_BRANCH_POST'
export const BANK_BRANCH_BACK = 'BANK_BRANCH_BACK'
export const BANK_ACCOUNT_CONFIRM_POST = 'BANK_ACCOUNT_CONFIRM_POST'
export const BANK_ACCOUNT_CONFIRM_BACK = 'BANK_ACCOUNT_CONFIRM_BACK'
export const BANK_ACCOUNT_POST = 'BANK_ACCOUNT_POST'
export const GET_LIST_BANK = 'GET_LIST_BANK'

//タイムライン
export const TIMELINE_GET = 'TIMELINE_GET'
export const TIMELINE_POST = 'TIMELINE_POST'
export const TIMELINE_DELETE = 'TIMELINE_DELETE'
export const TIMELINE_LIKE_GET = 'TIMELINE_LIKE_GET'
export const TIMELINE_COMMENT_GET = 'TIMELINE_COMMENT_GET'
export const TIMELINE_COMMENT_POST = 'TIMELINE_COMMENT_POST'
export const TIMELINE_COMMENT_DELETE = 'TIMELINE_COMMENT_DELETE'

// 顧客管理
export const CUSTOMER_MANAGEMENT_SUPPORT_HISTORY_GET =
	'CUSTOMER_MANAGEMENT_SUPPORT_HISTORY_GET'
export const CUSTOMER_MANAGEMENT_FOLLOWER_GETTING =
	'CUSTOMER_MANAGEMENT_FOLLOWER_GETTING'
export const CUSTOMER_MANAGEMENT_FOLLOWER_GET =
	'CUSTOMER_MANAGEMENT_FOLLOWER_GET'
export const CUSTOMER_MANAGEMENT_NOTIFICATION_GET =
	'CUSTOMER_MANAGEMENT_NOTIFICATION_GET'
export const CUSTOMER_MANAGEMENT_BLOCK_GET = 'CUSTOMER_MANAGEMENT_BLOCK_GET'
export const ADD_MARK_CUSTOMER_REPORT_V2 = 'ADD_MARK_CUSTOMER_REPORT_V2'

export const MISSION_GET = 'MISSION_GET'
export const MISSION_GET_V2 = 'MISSION_GET_V2'
export const MISSION_GET_DAILY = 'MISSION_GET_DAILY'
export const MISSION_GET_START_DASH = 'MISSION_GET_START_DASH'
export const MISSION_GET_ARCHIVED = 'MISSION_GET_ARCHIVED'
export const MISSION_GET_ARCHIVED_V2 = 'MISSION_GET_ARCHIVED_V2'
export const MISSION_START = 'MISSION_START'
export const MISSION_GET_POINT = 'MISSION_GET_POINT'
export const MISSION_GET_SPECIAL = 'MISSION_GET_SPECIAL'
export const MISSION_RESET = 'MISSION_RESET'

// レビュー
export const REVIEW_GET = 'REVIEW_GET'
export const PIN_REVIEW = 'PIN_REVIEW'
export const UNPIN_REVIEW = 'UNPIN_REVIEW'
export const UPDATE_PIN_STATUS_OF_REVIEW_ITEM =
	'UPDATE_PIN_STATUS_OF_REVIEW_ITEM'
export const GET_PINED_REVIEW = 'GET_PINED_REVIEW'
export const GET_REVIEWS_WITH_FILTERS = 'GET_REVIEWS_WITH_FILTERS'
export const UPDATE_REVIEW_TUTORIAL = 'UPDATE_REVIEW_TUTORIAL'
export const RESET_STATUS = 'RESET_STATUS'
export const RESET_REVIEW_LIST = 'RESET_REVIEW_LIST'
//ANSWER
export const ANSWER_MANAGEMENT_ANSWER_GET_ALL =
	'ANSWER_MANAGEMENT_ANSWER_GET_ALL'
export const ANSWER_MANAGEMENT_BEST_ANSWER_GET_ALL =
	'ANSWER_MANAGEMENT_BEST_ANSWER_GET_ALL'
export const ANSWER_MANAGEMENT_GOOD_ANSWER_GET_ALL =
	'ANSWER_MANAGEMENT_GOOD_ANSWER_GET_ALL'
export const ANSWER_MANAGEMENT_ANSWER_LOG_GET_ALL =
	'ANSWER_MANAGEMENT_ANSWER_LOG_GET_ALL'
export const GET_QUESTION_BY_ID = 'GET_QUESTION_BY_ID'
export const POST_TELLER_ANSWER = 'POST_TELLER_ANSWER'
export const UPDATE_POPUP_STATUS = 'UPDATE_POPUP_STATUS'
export const GET_UNREAD_BEST_ANSWER = 'GET_UNREAD_BEST_ANSWER'
export const BEST_ANSWER_ADD = 'BEST_ANSWER_ADD'
export const OPEN_WELCOME_ANSWER = 'OPEN_WELCOME_ANSWER'
export const CLOSE_WELCOME_ANSWER = 'CLOSE_WELCOME_ANSWER'
export const ANSWER_MANAGEMENT_ANSWER_GET_ALL_V2 = 'ANSWER_MANAGEMENT_ANSWER_GET_ALL_V2'
export const ANSWER_MANAGEMENT_ANSWER_GET_LOG_V2 = 'ANSWER_MANAGEMENT_ANSWER_GET_LOG_V2'
export const ADD_ANSWER_DRAFT = 'ADD_ANSWER_DRAFT'
export const REMOVE_ANSWER_DRAFT = 'REMOVE_ANSWER_DRAFT'

//CONTACT
export const CONTACT_GET_HISTORY = 'CONTACT_GET_HISTORY'
export const CONTACT_GET_DETAIL = 'CONTACT_GET_DETAIL'
export const CONTACT_POST_QUESTION = 'CONTACT_POST_QUESTION'
export const CONTACT_RESOLVE_QUESTION = 'CONTACT_RESOLVE_QUESTION'
export const CONTACT_GET_UNREAD = 'CONTACT_GET_UNREAD'

// IDLE ACTION
export const USER_IDLE_ACTION_SHOW_POPUP = 'USER_IDLE_ACTION_SHOW_POPUP'
export const USER_IDLE_ACTION_HIDE_POPUP = 'USER_IDLE_ACTION_HIDE_POPUP'
export const USER_IDLE_ACTION_CONTINUE_WAITING_SYNC =
	'USER_IDLE_ACTION_CONTINUE_WAITING_SYNC'
export const USER_IDLE_ACTION_FINISH_WAITING_SYNC =
	'USER_IDLE_ACTION_FINISH_WAITING_SYNC'

// change limit
export const CHANGE_LIMIT_USER_CHATTING = 'CHANGE_LIMIT_USER_CHATTING'
export const CHANGE_STATUS = 'CHANGE_STATUS'

// change limit
export const DETAIL_ANSWER_CHAT_LOG_DEFAULT = 'DETAIL_ANSWER_CHAT_LOG_DEFAULT'
export const DETAIL_ANSWER_CHAT_LOG = 'DETAIL_ANSWER_CHAT_LOG'

// suspend request chatting
export const DO_SUSPEND = 'DO_SUSPEND'
export const DO_SHOW_DIALOG_CHAT = 'DO_SHOW_DIALOG_CHAT'
export const DO_REMOVE_REQUEST = 'DO_REMOVE_REQUEST'

// call actions
export const OPEN_PRE_CALL_SCREEN = 'OPEN_PRE_CALL_SCREEN'
export const CHANGE_JOINSTATE = 'CHANGE_JOINSTATE'
export const CLOSE_PRE_CALL_SCREEN = 'CLOSE_PRE_CALL_SCREEN'
export const OPEN_IN_CALL_SCREEN = 'OPEN_IN_CALL_SCREEN'
export const CLOSE_IN_CALL_SCREEN = 'CLOSE_IN_CALL_SCREEN'
export const SHOW_PENDING_BELL_CALL = 'SHOW_PENDING_BELL_CALL'
export const HIDE_PENDING_BELL_CALL = 'HIDE_PENDING_BELL_CALL'
export const UPDATE_CALL_PARAMS = 'UPDATE_CALL_PARAMS'
export const GET_AGORA_CONFIGURATION = 'GET_AGORA_CONFIGURATION'
export const RESET_CALL_PARAMS = 'RESET_CALL_PARAMS'


// point report actions
export const GO_TO_POINT_REPORT_SCREEN_AT_FIRST_TIME = 'GO_TO_POINT_REPORT_SCREEN_AT_FIRST_TIME'
export const SET_TARGET_POINT = 'SET_TARGET_POINT'
export const GET_CHART_DATA = 'GET_CHART_DATA'
export const GET_CALENDAR_DATA = 'GET_CALENDAR_DATA'
export const GET_COMMON_DATA_FOR_POINT_REPORT = 'GET_COMMON_DATA_FOR_POINT_REPORT'
export const GET_DEFAULT_TARGET_POINT = 'GET_DEFAULT_TARGET_POINT'

//CategoryBlog
export const GET_LIST_CATEGORY_BLOG = 'GET_LIST_CATEGORY_BLOG'

export const UPDATE_TELLER_FREE_CHAT_SETTING = 'UPDATE_TELLER_FREE_CHAT_SETTING'


export const BANNER_WORD = 'BANNER_WORD'


//RankingEvent
export const GET_ALL_RANKING_EVENT = "GET_ALL_RANKING_EVENT"
export const UPDATE_RANKING_EVENT = "UPDATE_RANKING_EVENT"
export const GET_ALL_RANKING_EVENT_ANNOUNCED = "GET_ALL_RANKING_EVENT_ANNOUNCED"
export const GET_ALL_HISTORY_RANKING_EVENT = "GET_ALL_HISTORY_RANKING_EVENT"
export const UPDATE_RANKING_EVENT_ANNOUNCED = "UPDATE_RANKING_EVENT_ANNOUNCED"
export const GET_EVENT_SCHEDULE = "GET_EVENT_SCHEDULE"
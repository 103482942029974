import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import NextLogin from './NextLogin'
import Dialog from 'material-ui/Dialog'
import moment from 'moment'
import { connect } from 'react-redux'
import * as AnswerManagementActions from './../actions/AnswerManagementActions'

class Menu extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showNextLogin: false,
		}
	}

	componentWillMount() {
		this.props.activeItem(5)
	}

	checkDialogWelcomeStatus = () => {
		const { MyProfile, dispatch } = this.props
		if (MyProfile.data.isHiddenStellaAnswerPopup) {
			dispatch(AnswerManagementActions.closeDialogWelComeAnswer())
		} else {
			dispatch(AnswerManagementActions.openDialogWelComeAnswer())
		}
	}

	render() {
		return (
			<div>
				<div className="bottom_menu">
					<ul className="bottom_menu_list">
						<li role="presentation">
							<Link to="/home" data-badge="0">
								<span>ホーム</span>
							</Link>
						</li>
						<li role="presentation">
							<Link
								to="/home/timeline"
								data-badge="0"
								role="button"
							>
								<span>タイムライン</span>
							</Link>
						</li>
						<li role="presentation">
							<Link
								to="/home/answer"
								data-badge="0"
								role="button"
								onClick={this.checkDialogWelcomeStatus}
							>
								<span>教えて！先生</span>
							</Link>
						</li>
						<li role="presentation">
							<Link
								to="/home/customermanagement"
								data-badge="0"
								role="button"
							>
								<span>顧客管理</span>
							</Link>
						</li>
						<li role="presentation">
							<Link
								to="/home/reward"
								data-badge="0"
								role="button"
							>
								<span>精算</span>
							</Link>
						</li>
						<li role="presentation">
							<Link
								to="/home/review"
								onClick={() =>
									this.handeleBadge('numberUnreadReview')
								}
								role="button"
								data-badge
							>
								<span>レビュー</span>
							</Link>
						</li>
						<li role="presentation">
							<Link
								to={{
									pathname: "/home/event",
									params: {
										index: 0
									}
								}}
								role="button"
							>
								<span>イベント</span>
								{this.props.MyProfile.data
									.isClickTabPointReport ? null : (
									<span className={'new-border-title-menu'}>
										NEW
									</span>
								)}
							</Link>
						</li>
						<li role="presentation">
							<Link
								to={`/home/analysis/daily/${moment().format(
									'YYYYMM'
								)}`}
								data-badge="0"
								role="button"
							>
								<span>イベント</span>
							</Link>
						</li>
						<li role="presentation">
							<Link
								to="/home/introduction"
								data-badge="0"
								role="button"
							>
								<span>紹介</span>
							</Link>
						</li>
						<li role="presentation">
							<Link
								to="/home/setting"
								data-badge="0"
								role="button"
							>
								<span>設定</span>
								{this.props.MyProfile.data
									.isHiddenNewSampleVoice ? null : (
									<span className={'new-border-title-menu'}>
										NEW
									</span>
								)}
							</Link>
						</li>
						<li role="presentation">
							<Link to="/home/help" role="button" data-badge="0">
								<span>ヘルプ</span>
							</Link>
						</li>
					</ul>
					<div
						className="bottom_list_logout"
						onClick={() =>
							this.setState({
								showNextLogin: !this.state.showNextLogin,
							})
						}
					>
						<Link to={'#'} role="button">
							<span>ログアウト</span>
						</Link>
					</div>
				</div>
				<LogoutDialog
					show={this.state.showNextLogin}
					closeFunc={() => this.setState({ showNextLogin: false })}
				/>
			</div>
		)
	}
}

const LogoutDialog = ({ show, closeFunc }) => {
	if (!show) {
		return null
	}

	return (
		<Dialog
			open={true}
			modal={false}
			title="ログアウト"
			onRequestClose={() => closeFunc()}
			bodyClassName="dialog__content"
			contentStyle={customLogoutDialogStyle}
		>
			<p className="logout-dialog__text">
				次回のログイン予定を登録しておくと相談件数が向上します。
			</p>
			<NextLogin
				show={true}
				place="logout"
				closeFunc={() => closeFunc()}
			/>
		</Dialog>
	)
}

const customLogoutDialogStyle = {
	width: '90%',
}

const mapStateToProps = (state) => {
	return {
		MyProfile: state.MyProfile,
	}
}

export default connect(mapStateToProps)(Menu)

import React from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import moment from 'moment'
import { connect } from 'react-redux'
import { getNextMonth, getPreviousMonth, POINT_REPORT } from '../../../constants/PointReportConstants'
import { ButtonNavigate, ToolbarLabel, WrapperToolbar, WrapperToolbarScheduleEvent } from '../../PointReport/StyledPointReport/Calendar/StyledCustomCalendar'
import { colors } from '../../../util/StellaConstant'
import * as RankingEventActions from '../../../actions/RankingEventActions'

const Toolbar = (props) => {
	const { date, view, views, label, localizer, onNavigate, onView, children, dispatch } = props

	const onNavigateToPreviousMonth = () => {
		onNavigate(POINT_REPORT.BUTTON_NAVIGATE.PREVIOUS)

		// call api get point for prev month
		const currentMonth = moment(date)
		const prevMonth = getPreviousMonth(currentMonth)
		dispatch(RankingEventActions.getEventSchedule(prevMonth))
	}

	const onNavigateToNextMonth = () => {
		onNavigate(POINT_REPORT.BUTTON_NAVIGATE.NEXT)

		// call api get point for next month
		const currentMonth = moment(date)
		const nextMonth = getNextMonth(currentMonth)
		dispatch(RankingEventActions.getEventSchedule(nextMonth))
	}

	const isDisabledFutureMonth = moment(date).isSame(new Date(), 'month')

	return (
		<WrapperToolbarScheduleEvent>
			<ButtonNavigate
				type="button"
				buttonType={POINT_REPORT.BUTTON_NAVIGATE.PREVIOUS}
				onClick={onNavigateToPreviousMonth}
				disabled={isDisabledFutureMonth}
				style={{
					cursor: isDisabledFutureMonth ? 'not-allowed' : 'pointer'
				}}
			>
				<ArrowBackIosIcon fontSize='12'
					style={{
						color: isDisabledFutureMonth ? colors.oldLavender : colors.black,
						opacity: isDisabledFutureMonth ? 0.4 : 1
					}} />
			</ButtonNavigate>
			<ToolbarLabel>{label}</ToolbarLabel>
			<ButtonNavigate
				type="button"
				buttonType={POINT_REPORT.BUTTON_NAVIGATE.NEXT}
				onClick={onNavigateToNextMonth}
			>
				<ArrowForwardIosIcon
					fontSize='12'
				/>
			</ButtonNavigate>
		</WrapperToolbarScheduleEvent>
	)
}

const mapStateToProps = (state) => {
	return {
		PointReport: state.PointReport
	}
}

export default connect(mapStateToProps)(Toolbar)
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { formatPoint } from '../../../util/StellaConstant';
import '../StyledEventSchedule/EventRanking/EventRanking.scss'
import Pager from '../../PointReport/Frames/Common/Pager';
import * as RankingEventActions from '../../../actions/RankingEventActions'


const EventRankingList = (props) => {
    const { RankingEvent, dispatch } = props
    const [page, setPage] = useState(0)
    const [data, setData] = useState({ list: [], total: 0 })

    useEffect(() => {
        dispatch(RankingEventActions.getHistoryEvent(page))
    }, [])

    const handleLoadMore = (page) => {
        dispatch(RankingEventActions.getHistoryEvent(page))
    }

    useEffect(() => {
        setData(RankingEvent.lstHistoryEvent)
    }, [RankingEvent.lstHistoryEvent])

    return (
        <>
            <div className='contain' id='event-ranking'>
                <div className='title'>イベント履歴</div>
                <table className="table table-hover" >
                    <thead>
                        <tr className='table-secondary' style={{ backgroundColor: 'rgba(247, 247, 247, 1)' }}>
                            <td>
                                日付
                            </td>
                            <td>
                                イベント名
                            </td>
                            <td >順位</td>
                            <td >
                                受け取りポイント
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {data.list.map((i, index) => {
                            return (
                                <tr key={index.toString()} style={{ backgroundColor: i.notificationRanges < i.position ? 'rgb(239, 239, 239)' : '' }}>
                                    <td>
                                        {moment(i.fromTime).format('YYYY/MM/DD')}
                                    </td>
                                    <td>
                                        {i.rankingEventType}
                                    </td>
                                    <td >{i.position}位</td>
                                    <td >
                                        {formatPoint(i.point)} pts
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <Pager totalRecords={data.total} handlePage={(event, page) => {
                event.preventDefault()
                setPage(page)
                handleLoadMore(page)
            }} nowPage={page} />
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        RankingEvent: state.RankingEvent
    }
}
export default connect(mapStateToProps)(EventRankingList)
import React, { Component, useState } from 'react';
import Dialog from '@mui/material/Dialog'
import { withRouter } from 'react-router-dom';
import * as RankingEventActions from '../../actions/RankingEventActions'
import { connect } from 'react-redux';
import moment from 'moment';

export const STATUS_EVENT_RANKING = {
    NOT_ENTRY: 'NOT_ENTRY',
    ENTRIED: 'ENTRIED',
    IN_PRIZE_FIRST: 'IN_PRIZE_FIRST',
    IN_PRIZE_TWICE: 'IN_PRIZE_TWICE',
    IN_PRIZE_THIRD: 'IN_PRIZE_THIRD',
    OUT_OF_PRIZE_FIRST: 'OUT_OF_PRIZE_FIRST',
    OUT_OF_PRIZE_TWICE: 'OUT_OF_PRIZE_TWICE',
    OUT_OF_PRIZE_THIRD: 'OUT_OF_PRIZE_THIRD',
    EXPIRED: 'EXPIRED',
    PUBLISHED: 'PUBLISHED',

}

const DialogEventRanking = (props) => {
    const [show] = useState(props.show)
    let params = props.data.item
    const [isEntry, setEntry] = useState(params.status)


    const handleClose = () => {
        props.closeFunc()
    }

    const redirectListEvent = () => {
        props.history.push('/home/event', { index: 1 })
        props.closeFunc()
    }

    const handleJoinEvent = () => {
        props.dispatch(RankingEventActions.addTellerEntry(params.rankingEventId, () => {
            setEntry(STATUS_EVENT_RANKING.ENTRIED)
        }))

    }

    const timeAnnounced = () => {
        if (params.status === STATUS_EVENT_RANKING.IN_PRIZE_FIRST || params.status === STATUS_EVENT_RANKING.OUT_OF_PRIZE_FIRST) {
            return '次回の集計時間は 12時です'
        } else if (params.status === STATUS_EVENT_RANKING.IN_PRIZE_TWICE || params.status === STATUS_EVENT_RANKING.OUT_OF_PRIZE_TWICE) {
            return '次回の集計時間は 18時です'
        } else {
            return ''
        }

    }

    const checkPositionRanking = () => {
        if (params.status === STATUS_EVENT_RANKING.IN_PRIZE_FIRST || params.status === STATUS_EVENT_RANKING.IN_PRIZE_TWICE || params.status === STATUS_EVENT_RANKING.IN_PRIZE_THIRD) {
            return <div className='dialog-event-ranking-rank'>{params.position}<span>位</span></div>
        } else {
            return <div className='dialog-event-ranking-rank' style={{ fontSize: 41 }}>ランク外</div>
        }
    }

    const handleUpdatePopupAnnounced = () => {
        props.dispatch(RankingEventActions.updateShowPopupAnnounced(params.rankingEventId, () => { redirectListEvent() }))
    }

    const renderDialogJoinEvent = () => {
        return (
            <div className='dialog-join-event'>
                <img src='/img/banner_join_event.png' />
                <div className='dialog-event-view_info'>
                    <div className='dialog-event-time'>
                        {moment(params.toTime).format('YYYY年MM月DD日HH:mmまで')}
                    </div>
                    <button
                        disabled={isEntry !== STATUS_EVENT_RANKING.NOT_ENTRY}
                        onClick={handleJoinEvent}
                        className="dialog-event-ranking-button">
                        {isEntry === STATUS_EVENT_RANKING.NOT_ENTRY ? 'エントリーする' : 'エントリー済み'}
                    </button>
                </div>
                <div style={{ display: 'grid' }}>
                    <button
                        onClick={handleClose}
                        className={'dialog-event-btn-close'}
                    >
                        閉じる
                    </button>
                </div>

            </div>
        )
    }

    const renderRankingEvent = () => {
        return (
            <div className='dialog-join-event'>
                <img src='/img/banner_ranking_event.png' className='dialog-event-banner-img' />
                <div className='dialog-event-ranking-info'>
                    {checkPositionRanking()}
                </div>
                <div className='dialog-event-ranking-time-announced'>
                    <div className='dialog-event-ranking-text_time_publish' style={{ marginBottom: 30 }}>{timeAnnounced()}</div>
                </div>
                <button
                    onClick={handleClose}
                    className="dialog-event-ranking-button"
                    style={styles.btn_close}>
                    閉じる
                </button>
            </div>
        )
    }

    const renderCompletedEvent = () => {
        return (
            <div className='dialog-join-event'>
                <img src='/img/banner_completed_event.png' className='dialog-event-banner-img' />
                <div className='dialog-event-completed'>
                    <div className='dialog-event-ranking-text_time_publish'>現在集計中です</div>
                    <div className='dialog-event-ranking-text_time_publish'>しばらく</div>
                    <div className='dialog-event-ranking-text_time_publish'>お待ちください</div>
                </div>
                <button
                    onClick={handleClose}
                    className="dialog-event-ranking-button"
                    style={styles.btn_close}>
                    閉じる
                </button>
            </div>
        )
    }

    const renderRewardEvent = () => {
        return (
            <div className='dialog-join-event'>
                <img src='/img/banner_reward_event@3x.png' className='dialog-event-banner-img' style={{width: 328, height: 353}} />
                <div className='dialog-event-ranking-info'>
                    <div className='dialog-event-ranking-rank' style={{ color: '#11ad9d' }}>{params.position}<span>位</span></div>
                </div>
                <button
                    onClick={handleUpdatePopupAnnounced}
                    className="dialog-event-ranking-button"
                    style={styles.btn_confirm}>
                    受け取りポイントを見る
                </button>
            </div>
        )
    }

    const renderDialog = () => {
        const { item } = props.data
        switch (item.status) {
            case STATUS_EVENT_RANKING.NOT_ENTRY:
                return renderDialogJoinEvent()
            case STATUS_EVENT_RANKING.ENTRIED:
                return renderDialogJoinEvent()
            case STATUS_EVENT_RANKING.EXPIRED:
                return renderCompletedEvent()
            case STATUS_EVENT_RANKING.PUBLISHED:
                return renderRewardEvent()
            default:
                return renderRankingEvent();
        }
    }
    return (
        <div>
            <Dialog
                open={show}
                PaperProps={{
                    style: {
                        backgroundColor: "transparent",
                        boxShadow: "none",
                    },
                }}
            >
                {renderDialog()}
            </Dialog>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {}
}
export default withRouter(connect(mapStateToProps)(DialogEventRanking))

const styles = {
    text_time_publish: {

    },
    btn_close: {
        width: '35%',
        bottom: 10,
        position: 'absolute',
        right: '30%'
    },
    btn_confirm: {
        width: '60%',
        bottom: 10,
        position: 'absolute',
        right: '18%'
    }
}
import moment from 'moment'
import { GET_ALL_HISTORY_RANKING_EVENT, GET_ALL_RANKING_EVENT, GET_ALL_RANKING_EVENT_ANNOUNCED, GET_EVENT_SCHEDULE, UPDATE_RANKING_EVENT, UPDATE_RANKING_EVENT_ANNOUNCED } from '../constants/ActionTypes'

const initialState = {
    data: [],
    dataAnnounced: [],
    lstHistoryEvent: { list: [], total: 0 },
    lstEventSchedule: [],
}

export const RankingEvent = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_RANKING_EVENT: {
            return {
                ...state,
                data: action.data,
            }
        }
        case UPDATE_RANKING_EVENT: {
            let newArr = state.data.map(i => {
                if (i.rankingEventId === action.data) {
                    return { ...i, status: 'ENTRIED' }
                }
                return i
            })

            return {
                ...state,
                data: newArr,
            }
        }
        case GET_ALL_RANKING_EVENT_ANNOUNCED: {
            return {
                ...state,
                dataAnnounced: action.data,
            }
        }
        case UPDATE_RANKING_EVENT_ANNOUNCED: {
            let newArr = state.dataAnnounced.filter(i => i.rankingEventId !== action.data)
            return {
                ...state,
                dataAnnounced: newArr,
            }
        }
        case GET_ALL_HISTORY_RANKING_EVENT: {
            return {
                ...state,
                lstHistoryEvent: action.data,
            }
        }
        case GET_EVENT_SCHEDULE: {
            let newArr = []
            action.data.data.map(i => {
                if (i.events.length > 0) {
                    return newArr.push(...i.events)
                }
            })
            const getUnique = (arr, index) => {
                const unique = arr
                    .map(e => e[index])
                    .map((e, i, final) => final.indexOf(e) === i && i)
                    .filter(e => arr[e]).map(e => arr[e]);
                return unique;
            }
            const lstEvent = getUnique(newArr, 'id').map(i => {
                let start_event = moment(i.start_time).format('YYYYMM') !== action.data.currentMonth ? Number(moment(action.data.currentMonth, 'YYYYMM').startOf('months').format('x')) : Number(moment(i.start_time).startOf('days').format('x'))
                let end_event = moment(i.end_time).format('YYYYMM') !== action.data.currentMonth ? Number(moment(action.data.currentMonth, 'YYYYMM').endOf('months').format('x')) : Number(moment(i.end_time).endOf('days').format('x'))
                return {
                    id: i.id,
                    title: i.name,
                    start: start_event,
                    end: end_event,
                    color: i.event_color_type,
                    ...i
                }
            })
            return {
                ...state,
                lstEventSchedule: lstEvent,
            }
        }
        default: {
            return state
        }
    }
}

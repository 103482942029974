export const notificationType = (notificationType, friendName) => {
	switch (notificationType) {
		case 2: {
			return friendName + 'さんがオンラインになりました'
		}
		case 3: {
			return 'ログインボーナスを獲得しました'
		}
		case 4: {
			return friendName + 'さんがタイムラインを投稿しました'
		}
		case 5: {
			return 'タイムラインが承認されました'
		}
		case 6: {
			return 'タイムラインが否認されました'
		}
		case 7: {
			return 'タイムラインコメントが承認されました'
		}
		case 8: {
			return 'タイムラインコメントが否認されました'
		}
		case 9: {
			return 'コメント返信が承認されました'
		}
		case 10: {
			return 'コメント返信が否認されました'
		}
		case 11: {
			return 'プロフィール情報が更新されました'
		}
		case 12: {
			return 'プロフィール情報が一部更新されました'
		}
		case 13: {
			return 'プロフィール情報の更新が否認されました'
		}
		case 14: {
			return friendName + 'さんからチャット相談の依頼がありました'
		}
		case 15: {
			return friendName + 'さんから電話相談の依頼がありました'
		}
		case 17: {
			return friendName + 'さんからタイムラインにコメントがありました'
		}
		case 18: {
			return friendName + 'さんからコメントに返信がありました'
		}
		case 19: {
			return friendName + 'さんからいいねがつきました'
		}
		case 21: {
			return friendName + 'さんからお気に入り登録されました。無料メッセージでアピールしましょう！'
		}
		case 23: {
			return 'プロフィール画像の加工が完了しました。'
		}
		case 24: {
			return 'プロフィール画像は承認されました。'
		}
		case 25: {
			return 'プロフィール画像は否認されました。'
		}
		case 27: {
			return 'チャットリクエストがキャンセルされました。'
		}
		case 28: {
			return '通話リクエストがキャンセルされました。'
		}
		case 29: {
			return 'ログインボーナスを獲得しました'
		}
		case 30: {
			return 'ミッションをクリアしました'
		}
		case 35: {
			return '教えて先生の回答が承認されました。'
		}
		case 36: {
			return '教えて先生の回答が否認されました。'
		}
		case 37: {
			return 'あなたの回答がベストアンサーに選ばれました！'
		}
		case 38: {
			return friendName + 'さんからチャット相談リクエストがありました'
		}
		case 39: {
			return friendName + 'さんから電話相談リクエストがありました'
		}
		case 42: {
			return 'あなたの回答にGoodがつきました！'
		}
		case 41: {
			return '事務局から回答がありました。'
		}
		case 49: {
			return '紹介した占い師が紹介ボーナスをゲットしました。'
		}
		case 51: {
			return 'あなたの一言コメントは承認されました。'
		}
		case 52: {
			return 'あなたの一言コメントは否認されました。'
		}
		case 55: {
			return '年齢認証が承認されました。'
		}
		case 56: {
			return '年齢認証が否認されました。'
		}
		case 64: {
			return '紹介したお客様が登録を完了しました。紹介ボーナスが受け取れます！'
		}
		case 71: {
			return 'ランキングイベントのボーナスを加算しました！'
		}
		default: {
			return notificationType + friendName
		}
	}
}

export const notificationTypeLink = (notification) => {
	const notificationType = notification.notificationType
	switch (notificationType) {
		case 5:
		case 6:
		case 7:
		case 8:
		case 9:
		case 10:
		case 17:
		case 18:
		case 19: {
			return '/home/timeline'
		}
		case 11:
		case 12:
		case 13:
		case 23:
		case 24:
		case 25: {
			return '/home/setting'
		}
		case 14:
		case 15:
		case 21:
		case 27:
		case 28:
		case 38:
		case 39: {
			return `/home/chat/${notification.friendId}`
		}
		case 29:
		case 30:
		case 49:
		case 51:
		case 52: {
			return '/home'
		}
		case 35:
		case 36: {
			return '/home/answer/answer-log'
		}
		case 37: {
			return '/home/answer'
		}
		case 41: {
			return `/home/contact/detail/${notification.threadId}`
		}
		case 42: {
			return `/home/answer/detail/${notification.questionId}`
		}
		case 55: {
			return '/home/setting/account'
		}
		case 56: {
			return '/home/setting/account'
		}
		case 71: {
			return {
				pathname: '/home/event',
				params: {
					index: 1
				}
			}
		}
		default: {
			return '#'
		}
	}
}

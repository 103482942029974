const InitConfig = () => {
	// eslint-disable-next-line no-undef
	if (process.env.REACT_APP_ENV === 'prod') {
		return {
			api_base_url: 'https://api.stella-app.com',
			ws_base_url: 'https://ws.stella-app.com',
			img_base_url: 'https://picture.stella-app.com',
			outside_base_url: 'https://api2.stella-app.com',
			urlIntro: 'https://uranai.stella-app.com/introducerId/',
			api_webview_url: 'https://webview.stella-app.com',
			one_link_appflyer: 'https://appstella.onelink.me/1Ehc'
		}
	} else {
		return {
			api_base_url: 'https://api.dev.stella-app.com',
			ws_base_url: 'https://ws.dev.stella-app.com',
			img_base_url: 'https://picture.dev.stella-app.com',
			outside_base_url: 'https://api2.dev.stella-app.com',
			urlIntro: 'https://uranai.dev.stella-app.com/introducerId/',
			api_webview_url: 'https://webview.dev.stella-app.com',
			one_link_appflyer: 'https://stellastg.onelink.me/n4XL'
		}
	}
}

const initConfig = InitConfig()

/**
 * Glasに関する設定
 */
export const glasConfig = {
	application: 'stella',
	url_base: initConfig.api_base_url,
	url_websocket: initConfig.ws_base_url,
	url_image: initConfig.img_base_url,
	url_outside: initConfig.outside_base_url,
	url_webview: initConfig.api_webview_url,
	urlIntro: initConfig.urlIntro,
	url_one_link_appflyer: initConfig.one_link_appflyer,
	path_agora_configurations: '/ums/v1/applicationinfo/agora',
	path_teller_login: '/ums/v1/fortunetellersessions/',
	path_teller_info: '/ums/v1/fortunetellers/',
	path_password: '/ums/v1/tellerverifycodes',
	path_user_info: '/ums/v1/users/',
	path_user_info_v2: '/ums/v1/users/v2/',
	path_last_chat_read_v2: '/chat/v1/lastchats/read',
	path_last_chat_unread_v2: '/chat/v1/lastchats/unread',
	path_last_chat: '/chat/v1/lastchats/',
	path_number_unread_message: '/chat/v1/unreadmessage/teller',
	path_chat_request: '/ums/v1/requests/',
	path_offline_request: '/ums/v1/offlineuserrequests/',
	path_offline_request_v2: '/ums/v1/offlineuserrequests/v2',
	path_news: '/ums/v1/news',
	path_file: '/static/v1/files/',
	path_chat_file: '/chat/v1/files',
	path_template: '/ums/v1/templates/',
	path_memos: '/ums/v1/memos/',
	path_logcalls: '/ums/v1/logcalls',
	path_chat_history: '/chat/v1/histories',
	path_jpns_notifications: '/jpns/v1/notifications',
	path_jpns_update_notification: '/jpns/v1/devices/pushtoken',
	path_timeline: '/buzz/v1/posts/',
	path_contact: '/contact',
	path_timeline_like: '/buzz/v1/likes/',
	path_timeline_comment: '/buzz/v1/comments/',
	path_timeline_subcomment: '/buzz/v1/subcomments/',
	path_image: '/images',
	path_ageverify: '/static/v1/ageverify',
	path_ageverify_s3storage: '/static/v1/ageverify/s3storage',
	path_review: '/ums/v1/reviews',
	path_pined_review: '/ums/v1/reviews/pin',
	path_review_v2: '/ums/v1/reviews/tellerprofile/v2',
	path_pin_review: '/ums/v1/reviews/pin',
	path_unpin_review: '/ums/v1/reviews/unpin',
	path_update_review_tutorial: '/ums/v1/fortunetellers/hiddenreviewtutorial',
	path_customer_report: '/ums/v1/customerreports/',
	path_customer_report_v2: '/ums/v1/customerreports/v2',
	add_mark_customer_report_v2: '/ums/v1/customerreports/v2',
	path_customer_notification: '/ums/v1/fortunetellersettings/notification',
	path_answer: '/ums/v1/stellaanswer/',
	path_answer_v2: '/ums/v2/stellaanswer/userquestions',
	path_point_feasibleword: '/ums/v1/point/feasibleword',
	path_payment: '/payoff',
	path_payment_reports: '/payment/v1/reports',
	path_analysis: '/ums/v1/analysis',
	path_payment_history: '/payoff/history',
	path_bank: '/bank',
	path_bank_branch: '/siten',
	path_bank_account: '/account',
	refresh_token: '/ums/v1/token',
	path_missions: '/ums/v1/missions',
	path_get_banner: '/ums/v1/banners/web',
	path_setting_noti: '/ums/v1/fortunetellersettings/notification',
	path_block: '/ums/v1/blocks',
	path_get_idle_time:
		'/ums/v1/fortunetellersettings/othersetting/limittimenoaction',
	get_mission_introduction: '/ums/v1/missionintroduction',
	check_mission_introduction: '/ums/v1/missionintroduction/',
	get_list_achievement_introduction_home:
		'/ums/v1/missionintroduction/reward/introduction',
	get_achievement_teller_is_introduced:
		'/ums/v1/missionintroduction/reward/introduced',
	reward_point_introduction: '/ums/v1/missionintroduction/reward',
	get_point_setting_introduction: '/ums/v1/missionintroduction/setting',
	update_mission_introduce:
		'/ums/v1/missionintroduction/updatemissionintroduction',
	update_hidden_sample_voice:
		'/ums/v1/fortunetellers/updatehiddensamplevoice',
	maxNumberChat: 'maxnumberchat',
	get_reward_explain: '/ums/v1/fortunetellers/rewardexplain/',
	get_stellaanswer_withmyanswer:
		'/ums/v1/stellaanswer/userquestionsinchatdetail',
	get_detail_answer_in_chat: '/ums/v1/stellaanswer/myanswerdetail',
	get_list_good_answer: '/ums/v1/stellaanswer/best-answer-not-view-text',
	get_view_best_answer: '/ums/v1/stellaanswer/view-text-best-answer',
	get_history_export: '/payoff/history-export',
	update_status_point_report_screen: '/ums/v1/fortunetellers/clicktabpointreport',
	path_set_target_point: '/payment/v1/targetpoint',
	path_point_report_get_chart_data: '/payment/v1/pointreports/datachart',
	path_point_report_get_calendar_data: '/payment/v1/pointreports/calendar',
	path_point_report_get_common_data: '/payment/v1/pointreports/common',
	path_point_report_get_default_target_point: '/payment/v1/targetpoint',
	list_category_blog: '/wp-json/v1/get-list-category?target=teller',
	update_ringtone: '/ringtone?',
	mission_v2: '/ums/v2/missions',
	update_shown_best_anwser_text: '/ums/v1/fortunetellers/updateshowbestanswertext',
	get_list_message_pin: '/chat/v2/lastchats/',
	get_list_message_unread_unpin: '/chat/v2/lastchats/unread',
	get_list_message_read_unpin: '/chat/v2/lastchats/read',
	get_lst_blog: '/buzz/v1/blogs/web',
	blog_detail: '/buzz/v1/blogs/',
	blog_categories: '/buzz/v1/categories',
	update_teller_free_chat_setting: '/ums/v1/fortunetellersettings/freechat',
	get_all_review_teller_profile: '/ums/v1/reviews/tellerprofile/allreview',
	get_reason_report: '/ums/v1/tellerreport/reasons',
	report_user: '/ums/v1/tellerreport',
	get_bank_webview: '/account/getBankFavorite',
	get_setting_point_introduce_customer: '/ums/v1/introduces/tellerintroduceuser/setting',
	get_list_reward_introduce_customer: '/ums/v1/introduces/tellerintroduceuser/reward',
	get_reward_customer: '/ums/v1/introduces/tellerintroduceuser/reward',
	get_answer_settings: '/ums/v1/stellaanswer/answersetting',
	get_banned_word: '/ums/v1/bannedword?bannedType=',

	get_all_event_ranking: '/ums/v1/rankingevent',
	add_teller_entry: '/ums/v1/rankingevent/addtellerentry',
	update_show_popoup_announced: '/ums/v1/rankingevent/popuprankingannounced',
	get_all_event_ranking_history: '/ums/v1/rankingevent/history',
	get_all_ranking_announced: '/ums/v1/rankingevent/announced',
	get_list_reason_deny_avatar: '/ums/v1/applicationinfo/reasondenyavatar',
	get_event_calendar: '/ums/v1/eventcalendar',

	length_mood: 32,
	chatrequest_limit: 90,
	leftmenu_maxlength_history: 500,
	leftmenu_per_page_history: 24,
	leftmenu_maxlength_follower: 30,
	reward_commission: 540,
	reward_commission_new: 550,
	payoff_min: 5540,
	payoff_min_new: 5550,
	chatpoint_rate: 1.0,
	setting: {
		comment: 30,
		name: 12,
		genres: 9,
		methods: 6,
		styles: 3,
		message: 1500,
		phone: 11,
		profile: {
			width: 300,
			height: 300,
			drag: 1,
			maxTop: 200,
			circle: 125,
		},
	},
	template: {
		template: 10,
		title: 6,
	},
	account: {
		password: 6,
	},
	bank: {
		accountNo: 7,
		yucho: '9900',
	},
	timeline: {
		getPostSize: 20,
		autoLoadPixel: 100,
	},
	usermanagement: {
		management: 10,
		follower: 10,
		autoLoadPixel: 96,
		block: 10,
	},
	answermanagement: {
		answer: 20,
		bestAnswer: 20,
		answerLogs: 20,
		otherAnswer: 10,
		answerReplyLength: 500,
	},
	review: {
		size: 20,
		autoLoadPixel: 100,
	},
	contact: {
		kindUserSend: 0, // 会員送信
		kindAdminSend: 1,
	},
	maxlength_push_noti: 50,
	mintime_setting_noti: 15,
	sortASC: 1,
	sortDESC: 2,
	helpFaq: '/faq',
}

/**
 * 待機ステータス
 */
export const fortuneTellerStatusToString = {
	0: 'チャット/通話待機中',
	1: 'チャットのみ待機中',
	2: '通話のみ待機中',
	3: 'チャット相談中',
	4: 'チャット相談中',
	5: '通話相談中',
	6: 'オフライン',
}

/**
 * 待機ステータス
 */
export const fortuneTellerStatus = {
	waiting: 0,
	chat: 1,
	call: 2,
	chatting: 3,
	chattingFull: 4,
	calling: 5,
	offline: 6,
}

export const TELLER_STATUS = {
	WAITING: 0,
	CHAT: 1,
	CALL: 2,
	CHATTING: 3,
	CHATTING_FULL: 4,
	CALLING: 5,
	OFFLINE: 6,
}

/**
 * リクエストタイプ
 */
// export const requestTypes = {
//   1: "チャット",
//   2: "通話",
//   3: "？？",
//   4: "？？",
// };

/**
 * 外部APIに関しての設定
 */
// export const NexivConfig = {
//
// };

/**
 * 銀行
 */
export const representativeBank = [
	{ code: '0005', name: '三菱UFJ銀行' },
	{ code: '0001', name: 'みずほ銀行' },
	{ code: '0009', name: '三井住友銀行' },
	{ code: '0010', name: 'りそな銀行' },
	{ code: '0017', name: '埼玉りそな銀行' },
	{ code: '0397', name: '新生銀行' },
	{ code: '0033', name: 'PayPay銀行' },
	{ code: '0036', name: '楽天銀行(旧イーバンク)' },
	{ code: '9900', name: 'ゆうちょ銀行' },
	{ code: '0116', name: '北海道銀行' },
	{ code: '0501', name: '北洋銀行' },
	{ code: '0125', name: '七十七銀行' },
	{ code: '0130', name: '常陽銀行' },
	{ code: '0134', name: '千葉銀行' },
	{ code: '0138', name: '横浜銀行' },
	{ code: '0150', name: 'スルガ銀行' },
	{ code: '0149', name: '静岡銀行' },
	{ code: '0158', name: '京都銀行' },
	{ code: '0169', name: '広島銀行' },
	{ code: '0177', name: '福岡銀行' },
	{ code: '0190', name: '西日本シティ銀行' },
]

/**
 * カタカナ48音
 */
export const katakanaList = [
	{ initials: 'ア', katakana: 'ア' },
	{ initials: 'イ', katakana: 'イ' },
	{ initials: 'ウ', katakana: 'ウ' },
	{ initials: 'エ', katakana: 'エ' },
	{ initials: 'オ', katakana: 'オ' },
	{ initials: 'カ', katakana: 'カ' },
	{ initials: 'キ', katakana: 'キ' },
	{ initials: 'ク', katakana: 'ク' },
	{ initials: 'ケ', katakana: 'ケ' },
	{ initials: 'コ', katakana: 'コ' },

	{ initials: 'サ', katakana: 'サ' },
	{ initials: 'シ', katakana: 'シ' },
	{ initials: 'ス', katakana: 'ス' },
	{ initials: 'セ', katakana: 'セ' },
	{ initials: 'ソ', katakana: 'ソ' },
	{ initials: 'タ', katakana: 'タ' },
	{ initials: 'チ', katakana: 'チ' },
	{ initials: 'ツ', katakana: 'ツ' },
	{ initials: 'テ', katakana: 'テ' },
	{ initials: 'ト', katakana: 'ト' },

	{ initials: 'ナ', katakana: 'ナ' },
	{ initials: 'ニ', katakana: 'ニ' },
	{ initials: 'ヌ', katakana: 'ヌ' },
	{ initials: 'ネ', katakana: 'ネ' },
	{ initials: 'ノ', katakana: 'ノ' },
	{ initials: 'ハ', katakana: 'ハ' },
	{ initials: 'ヒ', katakana: 'ヒ' },
	{ initials: 'フ', katakana: 'フ' },
	{ initials: 'ヘ', katakana: 'ヘ' },
	{ initials: 'ホ', katakana: 'ホ' },

	{ initials: 'マ', katakana: 'マ' },
	{ initials: 'ミ', katakana: 'ミ' },
	{ initials: 'ム', katakana: 'ム' },
	{ initials: 'メ', katakana: 'メ' },
	{ initials: 'モ', katakana: 'モ' },
	{ initials: 'ヤ', katakana: 'ヤ' },
	{ initials: '', katakana: '　' },
	{ initials: 'ユ', katakana: 'ユ' },
	{ initials: '', katakana: '　' },
	{ initials: 'ヨ', katakana: 'ヨ' },

	{ initials: 'ラ', katakana: 'ラ' },
	{ initials: 'リ', katakana: 'リ' },
	{ initials: 'ル', katakana: 'ル' },
	{ initials: 'レ', katakana: 'レ' },
	{ initials: 'ロ', katakana: 'ロ' },
	{ initials: 'ワ', katakana: 'ワ' },
	{ initials: '', katakana: '　' },
	{ initials: '', katakana: '　' },
	{ initials: '', katakana: '　' },
	{ initials: '', katakana: '　' },
]

/**
 * タイムライン
 */
export const TimeLineTabDefine = {
	GET_ALL: 0,
	GET_FAVORITE: 1,
	GET_ID: 2,
}

export const TimeLineTab = ['all', 'favorite', 'id']

export const TimeLineContentType = {
	CONTENT: 1,
	IMAGE: 2,
	VIDEO: 3,
}

export const Gender = {
	1: '男性',
	2: '女性',
	3: 'その他',
}

export const GenderAvator = {
	1: '/img/thumb_male.png',
	2: '/img/thumb_female.png',
	3: '/img/thumb_noimage.png',
}

export const Styles = [
	'簡潔',
	'素早い',
	'ゆっくり',
	'じっくり',
	'丁寧',
	'優しい',
	'暖かい',
	'癒し',
	'ズバッと',
	'論理的',
	'ユーモア',
	'フレンドリー',
	'ポジティブ',
	'頼りになる',
	'聞き上手',
	'話し上手',
]

export const Methods = [
	'透視',
	'霊感',
	'送念',
	'祈願',
	'祈祷',
	'波動修正',
	'遠隔ヒーリング',
	'オーラ',
	'ルーン',
	'タロット',
	'オラクルカード',
	'ルノルマンカード',
	'パワーストーン',
	'数秘術',
	'東洋占星術',
	'西洋占星術',
	'夢占い',
	'血液型',
	'レイキ',
	'ダウジング',
	'スピリチュアル',
	'チャネリング',
	'チャクラ',
	'カウンセリング',
	'セラピー',
	'守護霊対話',
	'前世観',
	'易',
	'風水',
	'人相',
	'手相',
	'九星気学',
	'姓名判断',
	'四柱推命',
	'紫微斗数',
	'算命学',
]

export const Genres = [
	'相性',
	'結婚',
	'離婚',
	'夫婦仲',
	'復縁',
	'不倫',
	'縁結び',
	'縁切り',
	'遠距離恋愛',
	'同性愛',
	'三角関係',
	'金運',
	'仕事',
	'起業',
	'転職',
	'対人関係',
	'自分の気持ち',
	'相手の気持ち',
	'家庭問題',
	'運勢',
	'開運方法',
]

export const AvatarStatus = {
	PENDDING: 1,
	APPROVED: 2,
	DENY: 3,
	MODIFY: 4,
}

export const LIMIT_CHAT_DEFAULT = 8

export const LIMIT_SEND_PICTURES = 5

export const MissionLevel = {
	1: '初級',
	2: '中級',
	3: '上級',
	4: 'ゲリラ',
}
export const MissionLevelV2 = {
	1: 'スタートダッシュ',
	2: 'スタートダッシュ',
	3: 'スタートダッシュ',
	4: 'ゲリラ',
	5: 'デイリー'
}

export const MissionLevelId = {
	ELEMENTARY: 1,
	INTERMEDIATE: 2,
	ADVANCED: 3,
	SPECIAL: 4,
	DAILY: 5
}

export const MissionUnit = {
	GET_POINT: 'pts',
	RECIVE_MESSAGE_CHARATER: '文字',
	RECIVE_MESSAGE_TIMES: '回',
	SEND_CHARGE_TEXT_CHARACTER: '文字',
	SEND_FREE_TEXT_CHARACTER: '文字',
	SEND_FREE_TEXT_TIMES: '回',
	TELLER_HAS_ANSWER: '回',
	TELLER_HAS_BEST_ANSWER: '回',
	CALL_MINUTES: '分',
	WAITING_BOTH_MINUTES: '分',
	WAITING_CHAT_MINUTES: '分',
	WAITING_CALL_MINUTES: '分',
	TIMELINE_POSTED: '回',
	CONTINOUS_LOGIN: '日',
	PAYMENT_TIMES: '回',
	EDIT_PROFILE: '回',
	PROFILE_IMAGE_REGISTRATION: '回',
	REVIEW: '回',
}

export const QuestionStatusList = [
	{
		value: 1,
		label: '回答受付中',
	},
	{
		value: 0,
		label: 'すべて',
	},
	{
		value: 2,
		label: '回答終了',
	},
]

export const QuestionCategoryList = [
	{
		value: 0,
		label: 'すべて',
	},
	{
		value: 1,
		label: '結婚/離婚',
	},
	{
		value: 2,
		label: '縁結び',
	},
	{
		value: 3,
		label: '縁切り',
	},
	{
		value: 4,
		label: '復縁',
	},
	{
		value: 5,
		label: '不倫',
	},
	{
		value: 6,
		label: '恋愛',
	},
	{
		value: 7,
		label: '仕事',
	},
	{
		value: 8,
		label: '対人関係',
	},
	{
		value: 9,
		label: '運勢',
	},
	{
		value: 10,
		label: '金運',
	},
	{
		value: 11,
		label: 'メンタル',
	},
	{
		value: 12,
		label: 'その他',
	},
]

export const QuestionSortByList = [
	{
		value: 1,
		label: '新着順',
	},
	{
		value: 2,
		label: '古い順',
	},
	{
		value: 4,
		label: '回答が少ない順',
	},
	{
		value: 3,
		label: '回答が多い順',
	},
]

export const LeftOfflineSortList = [
	{
		value: 0,
		label: '受付日が新しい順',
		orderBy: 'sendTime',
		sortBy: 0,
	},
	{
		value: 1,
		label: '受付日が古い順',
		orderBy: 'sendTime',
		sortBy: 1,
	},
	{
		value: 2,
		label: '希望日が近い順',
		orderBy: 'requestTime',
		sortBy: 1,
	},
	{
		value: 3,
		label: '希望日が遠い順',
		orderBy: 'requestTime',
		sortBy: 0,
	},
]

export const QuestionStatus = {
	ALL: 0,
	ANSWERING: 1,
	ANSWERED: 2,
}

export const QuestionStatusResponse = {
	WAITING_FOR_REVIEW: 3,
	WAITING_FOR_BEST_ANSWER: 6,
	ANSWERING: 9,
	SOLVED: 12,
}

export const QuestionCategory = {
	ALL: 0,
	MARRIAGE_OR_DIVORCE: 1,
	MARRIAGE: 2,
	DIVORCE: 3,
	REPUDIATION: 4,
	INFIDELITY: 5,
	LOVE: 6,
	WORK: 7,
	INTERPERSONAL: 8,
	FORTUNE: 9,
	LUCKY_MONEY: 10,
	METAL: 11,
	OTHER: 12,
}

export const MESSAGE_SELECTED_ONE_TO_THREE = '3つ以上選択してください'
export const MESSAGE_NOT_SELECTED_GENRES = '得意ジャンルを選択してください'
export const MESSAGE_SELECTED_ONE = '1つ以上選択してください'
export const MESSAGE_NOT_SELECTED_STYLES = '相談スタイルを選択してください'
// export const MESSAGE_REQUIRE_INTRODUCE = '自己紹介を入力してください';
export const REQUIRE_THREE_SKILL = 3
export const REQUIRE_ONE_SKILL = 1
export const SETTING_TYPE = {
	GENRES: 'genres',
	METHODS: 'methods',
	STYLES: 'styles',
}

export const UPLOAD_AVATAR = {
	CAUSE: {
		1: 'お顔が画面に近すぎます',
		2: '画像が小さいか、ぼやけています',
		3: 'ご自身が小さすぎます',
		4: 'お顔を写してください',
		5: '過度な加工が行われています',
		6: '人物が一人でない写真が使われています',
		7: 'ご自身の写真ではない',
		8: '画像のファイル形式が適切ではありません',
		9: '画像・人物を中央正面に配置してください',
		10: '別サイトで使用されている画像です',
	},
	SOLUTION: {
		1: '顔全体が写り、画面から切れないようにしてください。頭部～胸元まで写し、頭頂部と左右に余白があるか確認してください。',
		2: '画像サイズが大きく、解像度の鮮明なものを送ってください。',
		3: 'バストアップ程度のものを使用してください。',
		4: '顔出しが難しい場合は、小道具や手のポーズで顔の一部を隠したり、後方を向く場合は耳、鼻、顎ラインが出て雰囲気がわかるようにしてください。',
		5: '色味の変更やスタンプ、装飾を行っていないものを使用してください。',
		6: '他人や動物などと写っていないものを使用してください。',
		7: '最近のご自身を写した写真をお使いください。',
		8: '利用できない形式の画像がアップロードされています。pngやjpg形式の画像をアップロードしてください。',
		9: '人物が片方に寄りすぎています。人物をできるだけ中央に配置し体が切れすぎないようにしてください。',
		10: 'インターネット上で使用が確認された画像です。ご自身の画像であってもネット上で使用したことのない画像を用意してください。',
	},
}

export const FILTER_STAR_OPTIONS = [
	{ id: 1, label: '高評価のみ', value: 'high', key: 'greater-than-3' },
	{ id: 2, label: '低～中評価のみ', value: 'low', key: 'less-than-3' },
]

export const LOAD_MORE_SIZE = 20

export const SOUNDS = {
	CALL: {
		RINGING: {
			PATH: '/call_ringing.wav',
			NAME: 'RINGING'
		}
	}
}

export const DEVICE_TYPE_FOR_CALLING_SIP_SERVER = 8

export const TYPE_ENABLE_CLOSE_CHAT = [
	'SEND_MSG_ACCEPT_CHAT',
	'CHARGE_TEXT',
	'SEND_FILE',
	'SEND_STAMP',
	'SEND_GIFT',
	'SENT_TEXT',
	'FREE_TEXT',
	'SEND_FILE_FREE'
]
export const CATEGORY_MISSTION = {
	START_DASH: 'START_DASH',
	DAILY: 'DAILY',
	SPECIAL: 'SPECIAL',
	ACHIEVED: 'ACHIEVED'
}

export const ROUTER_PAGE = {
	PAGE_BEST_ANSWER: '/home/answer/best-answer'
}

export const MESSAGE_TYPE_SOCKET = {
	AUTO_MESSAGE_PICK_BEST_ANSWER: 'AUTO_MESSAGE_PICK_BEST_ANSWER'
}

export const ANSWER_TELLER = {
	LABEL_CHECKBOX_ANSWER_APPROVE: '狙い目のみ表示',
	LABEL_CHECKBOX_ANSWER_BEST_ANSWER: 'ベストアンサーに選ばれたもののみ表示',
}

export const MAX_SIZE_ANSWER_SHOW = 20

export const TEXT_CHAT_DRAFT = '「下書き」'

export const RESPONSE_CODE_SERVER = {
	TOKEN_EXPIRED: 4,
	REVIEW_CODE: 64
}

export const MESSAGE_TEXT_ERROR = {
	VALIDATE_IMAGE: "ファイル形式が正しくありません。jpg, jpeg, pngいずれかの形式のファイルをアップロードしてください。",
	VALIDATE_IMAGE_MAX_SIZE: "ファイルサイズが大きすぎます。10MB以下のファイルをアップロードしてください。"
}

export const VARIABLE_STATIC = {
	SIZE_LENGTH: 1024,
	MAX_SIZE_IMAGE_UPLOAD: 10
}

export const TYPE_LOCALSTORAGE = {
	PUSH_NOTIFICATION_TOKEN: 'pushNotificationToken',
	SHOW_DIALOG_NEWBIE: 'show-dialog-newbie'
}

export const TYPE_OPTION_BELL_DEVEICE = {
	OPTION_ON: "ON",
	OPTION_OFF: "OFF"
}

export const EVENT_TABS = [
	{ id: 1, label: 'イベントスケジュール', value: 'schedule' },
	{ id: 2, label: 'イベント履歴', value: 'event' },
]

export const COLOR_EVENT_SCHEDULE = [
	{ label: 'rgba(251, 132, 188, 1)', value: 'PINKY_COLOR' },
	{ label: 'rgba(255, 180, 58, 1)', value: 'YELLOW_COLOR' },
	{ label: 'rgba(173, 129, 222, 1)', value: 'SKY_BLUE_COLOR' },
]
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { isMobile, isAndroid } from 'react-device-detect'

class AnalysisMenu extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		let pathName = window.location.pathname
		let dailyAnalysis =
			'btn-tab' +
			(pathName.indexOf('/home/analysis/daily') !== -1
				? ' is-active'
				: '')
		let weeklyAnalysis =
			'btn-tab' +
			(pathName.indexOf('/home/analysis/weekly') !== -1
				? ' is-active'
				: '')
		let monthlyAnalysis =
			'btn-tab' +
			(pathName.indexOf('/home/analysis/monthly') !== -1
				? ' is-active'
				: '')
		let periodicallyAnalysis =
			'btn-tab' +
			(pathName.indexOf('/home/analysis/period') !== -1
				? ' is-active'
				: '')
		let pointReport =
			'btn-tab' +
			(pathName.indexOf('/home/analysis/point-report') !== -1
				? ' is-active'
				: '')
		let calendar =
			'btn-tab' +
			(pathName.indexOf('/home/analysis/calendar') !== -1
				? ' is-active'
				: '')

		return (
			<div>
				<nav className="lead-nav">
					<ul className="list lead_nav list-nav-analysis">
						<li className="list__item">
							<Link
								className={pointReport}
								to={`/home/analysis/point-report`}
							>
								{'レポート'}
							</Link>
						</li>
						<li className="list__item">
							<Link
								className={calendar}
								to={`/home/analysis/calendar`}
							>
								{'カレンダー'}
							</Link>
						</li>
						<li className="list__item">
							<Link
								className={dailyAnalysis}
								to={`/home/analysis/daily/${moment().format(
									'YYYYMM'
								)}`}
							>
								{isMobile ? '日別' : 'アクセス解析 - 日'}
							</Link>
						</li>
						<li className="list__item">
							<Link
								className={weeklyAnalysis}
								to={`/home/analysis/weekly/${moment().format(
									'YYYYMM'
								)}`}
							>
								アクセス解析 - 週間{' '}
							</Link>
						</li>
						<li className="list__item">
							<Link
								className={monthlyAnalysis}
								to={`/home/analysis/monthly/${moment().format(
									'YYYY'
								)}`}
							>
								アクセス解析 - 月間
							</Link>
						</li>
						<li className="list__item">
							<Link
								className={periodicallyAnalysis}
								to={`/home/analysis/periodically`}
							>
								アクセス解析 - 期間
							</Link>
						</li>
					</ul>
				</nav>
			</div>
		)
	}
}

export default AnalysisMenu

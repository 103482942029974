import React, { Component } from 'react'
import { PrivateRoute } from './PrivateRoute'
import DailyAnalysis from './DailyAnalysis'
import WeeklyAnalysis from './WeeklyAnalysis'
import MonthlyAnalysis from './MonthlyAnalysis'
import PeriodicallyAnalysis from './PeriodicallyAnalysis'
import PointReport from './PointReport/PointReport'
import MainCalendarReport from './PointReport/MainCalendarReport'

class RouteAnalysis extends Component {
	render() {
		return (
			<span>
				<PrivateRoute
					exact
					strict
					path={`${this.props.match.path}/point-report`}
					component={PointReport}
				/>
				<PrivateRoute
					exact
					strict
					path={`${this.props.match.path}/calendar`}
					component={MainCalendarReport}
				/>
				<PrivateRoute
					exact
					strict
					path={`${this.props.match.path}/daily/:month`}
					component={DailyAnalysis}
				/>
				<PrivateRoute
					exact
					strict
					path={`${this.props.match.path}/weekly/:month`}
					component={WeeklyAnalysis}
				/>
				<PrivateRoute
					exact
					strict
					path={`${this.props.match.path}/monthly/:year`}
					component={MonthlyAnalysis}
				/>
				<PrivateRoute
					exact
					strict
					path={`${this.props.match.path}/periodically`}
					component={PeriodicallyAnalysis}
				/>
			</span>
		)
	}
}

export default RouteAnalysis

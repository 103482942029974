import React, { useState, useEffect, useMemo } from 'react'
import { ScreenName, StyledContainerMainContent } from './StyledPointReport'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import SwipeableViews from 'react-swipeable-views'
import { TabPanel } from './Frames/Common/TabPanel'
import CustomTabs from './Frames/Common/Tabs'
import { POINT_REPORT } from '../../constants/PointReportConstants'
import CustomTab from './Frames/Common/Tab'
import ReportChart from './Frames/Tabs/ReportChart'
import ReportCalendar from './Frames/Tabs/ReportCalendar'
import DialogPointReportGuide from './Dialogs/DialogPointReportGuide'
import DialogSetTargetPoint from './Dialogs/DialogSetTargetPoint'
import { connect } from 'react-redux'
import * as PointReportActions from '../../actions/PointReportActions'
import * as MyProfileActions from '../../actions/MyProfileActions'
import EventRankingList from '../Event/Tabs/EventRankingList'
import AnalysisMenu from '../AnalysisMenu'

const POIN_REPORT_MENU = {
	CHART: 0,
	CALENDAR: 1,
	RANKING_EVENT: 2
}

const MainCalendarReport = props => {
	const { dispatch, TellerProfile, PointReportData } = props
	const [value, setValue] = useState(POIN_REPORT_MENU.CHART)
	const [isShowSetTarget, setShowTargetPoint] = useState(false)
	const [targetPoint, setTargetPoint] = useState(null)
	useEffect(() => {
		if (!TellerProfile.data.isClickTabPointReport) {
			dispatch(PointReportActions.updateStatusPointReportScreen())
		}

		// get common data for reporting
		dispatch(PointReportActions.getCommonData())
		dispatch(PointReportActions.getDefaultTargetPoint())

		if (props.history.location.state) {
			setValue(props.history.location.state.index)
		} else {
			setValue(POIN_REPORT_MENU.RANKING_EVENT)
		}
		if (props.history.location.params) {
			setValue(props.history.location.params.index)
		} else {
			setValue(POIN_REPORT_MENU.RANKING_EVENT)
		}
	}, [])

	useEffect(() => {
		dispatch(MyProfileActions.merge({ isClickTabPointReport: true }))
	}, [PointReportData.isVisited])

	useEffect(() => {
		const target = PointReportData.commonData.value.targetPointThisMonth
		if (target) {
			setTargetPoint(target)
		}
	}, [PointReportData.commonData.value])

	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	const handleChangeIndex = (index) => {
		setValue(index)
	}

	const openSetTargetPoint = () => setShowTargetPoint(true)

	const closeSetTargetPoint = () => setShowTargetPoint(false)

	const handleSetTargetPoint = point => {
		setTargetPoint(point)
	}

	return (
		<div className="content reward analysis-daily">
			<h1 className="content__title">アクセス解析</h1>
			<AnalysisMenu />
			<div className="primary primaryReward primaryAnalysis">
				{
					PointReportData.commonData.isLoaded === true
						? <ReportCalendar
							targetPoint={targetPoint}
							openSetTargetPoint={openSetTargetPoint}
							PointReportData={PointReportData}
						/>
						: null
				}
			</div>
			<DialogPointReportGuide
				openSetTargetPoint={openSetTargetPoint}
			/>
			<DialogSetTargetPoint
				show={isShowSetTarget}
				dispatch={dispatch}
				tellerId={TellerProfile.data.fortuneTellerId}
				targetPoint={targetPoint}
				setTargetPoint={handleSetTargetPoint}
				closeSetTargetPoint={closeSetTargetPoint}
				pointEarnedLastMonth={PointReportData.commonData.value.pointEarnedLastMonth}
			/>
		</div>
	)
}
const mapStateToProps = (state) => {
	return {
		TellerProfile: state.MyProfile,
		PointReportData: state.PointReport,
	}
}

export default connect(mapStateToProps)(MainCalendarReport)
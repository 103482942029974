import styled from 'styled-components'
import { POINT_REPORT } from '../../../../constants/PointReportConstants'
import { colors, fonts, DEVICE } from '../../../../util/StellaConstant'

/*
	@media ${DEVICE.mobileS} {

	}
	@media ${DEVICE.laptop} {

	}
	@media ${DEVICE.laptopL} {

	}
*/

const COLORS = {
	SATURDAY: {
		color: colors.brandeisBlue
	},
	SUNDAY: {
		color: colors.red
	},
	NORMAL_DAY: {
		color: colors.taupe
	}
}

export const MonthHeaderWrapper = styled.div`
	@media ${DEVICE.mobileS} {
		background-color: ${colors.munsell}
	}
	@media ${DEVICE.laptop} {

	}
	@media ${DEVICE.laptopL} {

	}
`

export const DayInMonthWrapper = styled.div`
	@media ${DEVICE.mobileS} {
		background-color: ${colors.munsell}
	}
	@media ${DEVICE.laptop} {

	}
	@media ${DEVICE.laptopL} {

	}
`

export const MonthHeaderLabel = styled.span`
	@media ${DEVICE.mobileS} {
		font-family: ${fonts.HiraKakuProW3};
		font-size: 10px;
		color: ${props => COLORS[props.day].color};
		text-align: center;
	}
	@media ${DEVICE.laptop} {

	}
	@media ${DEVICE.laptopL} {

	}
`

export const DayInMonthLabel = styled.span`
	@media ${DEVICE.mobileS} {
		display: flex;
		font-family: ${props => props.isToday ? fonts.HiraKakuProW6 : fonts.HiraKakuProW3};
		font-size: 10px;
		color: ${props => COLORS[props.day].color};
		opacity: ${props => props.isOffRange ? 0.4 : 1};
		padding: 1px 3px;
	}
	@media ${DEVICE.laptop} {
		font-size: 14px;
		padding: 5px 10px;
	}
	@media ${DEVICE.laptopL} {
		font-size: 14px;
		padding: 5px 10px;
	}
`

export const Point = styled.span`
	@media ${DEVICE.mobileS} {
		font-family: ${fonts.HiraKakuProW3};
		font-size: 10px;
		color: ${colors.primaryText};
		margin-right: 3px;
	}
	@media ${DEVICE.laptop} {
		font-size: 16px;
		color: ${colors.pearlAqua};
		margin-right: 5px;
	}
	@media ${DEVICE.laptopL} {
		margin-right: 6px;
	}
`

export const WrapperToolbar = styled.div`
	@media ${DEVICE.mobileS} {
		display: grid;
		width: calc(100% - 25px);
		grid-template-areas:
			'PREV LABEL NEXT';
		margin: 12px 13px;
		position: absolute;
		top: -190px;
	}
	@media ${DEVICE.laptop} {
		grid-template-areas:
			'PREV LABEL NEXT';
		width: calc(100% - 57px);
	}
	@media ${DEVICE.laptopL} {
		grid-template-areas:
			'PREV LABEL NEXT';
		width: calc(100% - 57px);
	}
`

export const ButtonNavigate = styled.button`
	@media ${DEVICE.mobileS} {
		grid-area: ${props => props.buttonType};
		width: 30px;
		height: 21px;
		background-color: ${colors.white};
		display: flex;
		justify-content: center;
		align-items: center;
		padding-left: 10px;
		// border: ${props => props.buttonType === POINT_REPORT.BUTTON_NAVIGATE.PREVIOUS ? 'none' : '0.1px solid rgba(0,0,0,0.26)'};
		border: 0.1px solid rgba(0,0,0,0.26);
		border-radius: 3px;
		border-top-right-radius: ${props => props.buttonType === POINT_REPORT.BUTTON_NAVIGATE.PREVIOUS ? '0px' : '3px'};
		border-bottom-right-radius: ${props => props.buttonType === POINT_REPORT.BUTTON_NAVIGATE.PREVIOUS ? '0px' : '3px'};
		border-top-left-radius: ${props => props.buttonType === POINT_REPORT.BUTTON_NAVIGATE.NEXT ? '0px' : '3px'};
		border-bottom-left-radius: ${props => props.buttonType === POINT_REPORT.BUTTON_NAVIGATE.NEXT ? '0px' : '3px'};
		justify-self: ${props => props.buttonType === POINT_REPORT.BUTTON_NAVIGATE.NEXT ? 'end' : 'start'};
	}
	@media ${DEVICE.laptop} {
		border: 0.1px solid rgba(0,0,0,0.26);
		width: 59px;
		height: 33px;
		justify-self: ${props => props.buttonType === POINT_REPORT.BUTTON_NAVIGATE.NEXT ? 'end' : 'start'};
	}
	@media ${DEVICE.laptopL} {
	}
`

export const ToolbarLabel = styled.span`
	@media ${DEVICE.mobileS} {
		grid-area: LABEL;
		font-family: ${fonts.HiraKakuProW3};
		font-size: 13px;
		color: ${colors.black};
		margin-right: 16px;
		align-self: flex-end;
		justify-self: center;
		width: 130px;
	}
	@media ${DEVICE.laptop} {
		font-size: 14px;
		justify-self: center;
	}
	@media ${DEVICE.laptopL} {
		font-size: 20px;
	}
`

export const WrapperToolbarScheduleEvent = styled.div`
	@media ${DEVICE.mobileS} {
		display: grid;
		grid-template-areas:
			'LABEL PREV NEXT';
		margin: 12px 13px;
		position: fixed;
		top: 0;
	}
	@media ${DEVICE.laptop} {
		grid-template-areas:
			'LABEL PREV NEXT';
	}
	@media ${DEVICE.laptopL} {
		grid-template-areas:
			'LABEL PREV NEXT';
	}
`